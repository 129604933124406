import * as Actions from 'actions'
import cx from 'classnames'
import { TranslationContext } from 'contexts/TranslationContext'
import { useAction } from 'hooks'
import { some } from 'lodash'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useSelector } from 'react-redux'
import { SelectableGroup } from 'react-selectable-fast-control'
import * as PT from '~/PropTypes'
import { CircularProgress } from '~/components/base/CircularProgress/CircularProgress'
import Scrollbars from '~/components/base/Scrollbars'
import { DRAGNDROP_TYPE, MENU_ITEMS, SOURCE_FILE_TYPES } from '~/enums'
import { useRefWithSetter } from '~/hooks/utils'
import * as Assets from '~/models/Asset'
import { Folder } from '~/models/Folder'
import * as Selectors from '~/selectors'
import styles from './BaseAssetsList.module.scss'
import './BaseAssetsList.scss'

const empty = () => {}

export const BaseAssetsListContext = React.createContext({
  selectedItems: [],
  setSelectedItems: () => {},
})

function BaseAssetsList(props) {
  const {
    className,
    /** @type {Asset[]} */
    items,
    loading,
    header,
    ItemComponent,
    before,
    placeholder,
    after,
    float,
    onScroll,
    fullHeightScrollbar,
    classes,
  } = props
  const { selectedItems, setSelectedItems } = useContext(BaseAssetsListContext)
  const [ selectableGroupRef, setSelectableGroupRef ] = useRefWithSetter()
  const onMoveMediaItemsToFolder = useAction(
    Actions.sourceFiles.moveMediaItemsToFolder
  )
  const { menuItem } = useSelector(state => state.mainView)

  const handleSelectionClear = () => setSelectedItems([])
  const [ , drag ] = useDraggable(
    {
      onMoveMediaItemsToFolder,
      handleSelectionClear,
    },
    selectedItems
  )

  const isItemsDraggable = selectedItems.length <= 1

  useEffect(handleSelectionClear, [ items ])

  const handleItemMouseDown = __CFG__.SOURCE_FILES_MANAGEMENT.SELECTABLE
    ? (assetId, fn) => {
      if (!selectedItems.map(item => item.id).includes(assetId)) {
        selectableGroupRef.current.clearSelection()
        fn()
      }
    }
    : empty

  const handleItemClick = __CFG__.SOURCE_FILES_MANAGEMENT.SELECTABLE
    ? (assetId, fn) => {
      if (selectedItems.map(item => item.id).includes(assetId)) {
        selectableGroupRef.current.clearSelection()
        fn()
      }
    }
    : empty

  const Wrapper = useMemo(
    () => (fullHeightScrollbar ? 'div' : Scrollbars),
    [ fullHeightScrollbar ]
  )
  const { t } = useContext(TranslationContext)

  return (
    <div className={cx('assets-list', className)}>
      <div className="assets-list__header">{header}</div>
      <Choose>
        <When condition={loading}>
          <CircularProgress
            size={100}
            endless
            percents={80}
            text={`${t('MEDIA_PROGRESS_TEXT_LOADING')}..`}
            transparent
          />
        </When>
        <Otherwise>
          <Wrapper
            {...(!fullHeightScrollbar
              ? {
                renderViewClassName: 'media-scrollbar',
                onScrollFrame: __CFG__.SOURCE_FILES_MANAGEMENT
                  .LOAD_MEDIA_ONSCROLL
                  ? onScroll
                  : empty,
              }
              : { style: { height: '100%' } })}
          >
            <Choose>
              <When condition={!items?.length}>{placeholder}</When>
              <Otherwise>
                <SelectableGroup
                  ref={setSelectableGroupRef}
                  className={styles.selectableGroup}
                  disabled={
                    !__CFG__.SOURCE_FILES_MANAGEMENT.SELECTABLE
                    || menuItem !== MENU_ITEMS.MEDIA
                  }
                  selectboxClassName={styles.selectBox}
                  scrollContainer=".scroll-container__content.media-scrollbar"
                  selectOnClick
                  deselectOnEsc
                  resetOnStart
                  allowCtrlClick
                  allowShiftClick
                  ignoreList={[ '.source-asset div' ]}
                  onSelectionClear={handleSelectionClear}
                  onSelectionFinish={group => {
                    if (group.length === 0) return
                    setSelectedItems(
                      group.map(item => ({
                        id: item.props.asset.id,
                        name: item.props.asset.name,
                        type: getDraggableType(item.props.asset),
                        hasError: item.props.asset.hasError,
                        uploading: item.props.asset.uploading,
                        url: item.props.asset?.url || '',
                        path: item.props.asset?.path || '',
                      }))
                    )
                  }}
                >
                  <div
                    className={cx('assets-list__items', classes.list)}
                    ref={drag}
                  >
                    {before}
                    {items.map((item, index) => ItemComponent({
                      // eslint-disable-next-line react/no-array-index-key
                      key: `${item.id}-${index}`,
                      asset: item,
                      draggable: isItemsDraggable,
                      onItemMouseDown: handleItemMouseDown,
                      onItemClick: handleItemClick,
                    }))}
                  </div>
                </SelectableGroup>
              </Otherwise>
            </Choose>
            {after}
          </Wrapper>
          <div>{float}</div>
        </Otherwise>
      </Choose>
    </div>
  )
}

BaseAssetsList.propTypes = {
  items: PropTypes.arrayOf(PT.MediaItem),
  loading: PropTypes.bool,
  header: PropTypes.element.isRequired,
  ItemComponent: PropTypes.elementType.isRequired,
  before: PropTypes.element,
  placeholder: PropTypes.element,
  after: PropTypes.element,
  float: PropTypes.element,
  onScroll: PropTypes.func,
  fullHeightScrollbar: PropTypes.bool,
  classes: PropTypes.shape({ root: PropTypes.string, list: PropTypes.string }),
}

BaseAssetsList.defaultProps = {
  items: [],
  loading: false,
  before: null,
  placeholder: null,
  after: null,
  float: null,
  onScroll: () => {},
  fullHeightScrollbar: false,
  classes: { root: '', list: '' },
}

function getDraggableType(asset) {
  switch (asset.constructor) {
    case Assets.VideoAsset:
      return DRAGNDROP_TYPE.MEDIA_ITEM
    case Assets.ImageAsset:
      return DRAGNDROP_TYPE.IMAGE_ITEM
    case Assets.TextAsset:
      return DRAGNDROP_TYPE.TEXT_ITEM
    case Assets.AudioAsset:
      return DRAGNDROP_TYPE.AUDIO_ITEM
    case Assets.TransitionAsset:
      return DRAGNDROP_TYPE.TRANSITION_ITEM
    case Folder:
      return DRAGNDROP_TYPE.FOLDER
    default:
      throw new Error(`Unsupported asset type: ${asset.constructor.name}`)
  }
}

function useDraggable(options, ...deps) {
  const setDndDropTarget = useAction(Actions.mainView.setDndDropTarget)
  const { onMoveMediaItemsToFolder, handleSelectionClear, onDenied } = options
  const [ selectedItems ] = deps
  const [ collected, drag, preview ] = useDrag(
    {
      item: { type: DRAGNDROP_TYPE.GROUP, selectedItems },
      begin: () => {
        setDndDropTarget('root')
      },
      end(item, monitor) {
        if (item.type === DRAGNDROP_TYPE.GROUP && monitor.didDrop()) {
          const folders = item.selectedItems
            .filter(item => item.type === DRAGNDROP_TYPE.FOLDER)
            .map(item => item.id)
          const files = item.selectedItems
            .filter(item => item.type !== DRAGNDROP_TYPE.FOLDER)
            .map(item => item.id)

          onMoveMediaItemsToFolder({
            folders,
            files,
            targetFolder: monitor.getDropResult().id,
            type: SOURCE_FILE_TYPES.MEDIA,
          })

          handleSelectionClear()
        }
      },
      canDrag() {
        if (
          some(selectedItems, item => item.uploading)
          && !some(selectedItems, item => item.hasError)
        ) {
          onDenied()
          return false
        }
        return selectedItems.length > 1
      },
    },
    deps
  )

  React.useEffect(() => {
    preview(getEmptyImage())
  }, [ preview ])
  return [ collected, drag ]
}

// ---

function AssetsListContainer(props) {
  const { type, ...rest } = props
  const items = useSelector(state => Selectors.getSourceFiles(state, type))
  const loading = useSelector(state => Selectors.getIsSourceFilesLoading(state, type))
  const sourceFilesParams = useSelector(state => Selectors.selectSourceFilesParams(state, type))
  const loadingShow = loading && sourceFilesParams.start === 0

  const [ selectedItems, setSelectedItems ] = useState([])
  const context = { selectedItems, setSelectedItems }

  return (
    <BaseAssetsListContext.Provider value={context}>
      <BaseAssetsList {...rest} items={items} loading={loadingShow} />
    </BaseAssetsListContext.Provider>
  )
}

AssetsListContainer.propTypes = {
  type: PT.SourceFileType.isRequired,
}

// ---

export default AssetsListContainer
