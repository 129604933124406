/* eslint-disable no-throw-literal */
import axiosCore, { AxiosError, AxiosResponse } from 'axios'
import { frontendVersion } from 'helpers/versionRoutines'
import { httpClient } from 'ServerAPI'
import Url from 'url'

import { typesDef } from 'WebSocket'
import { ActivationError, ConnectionActivationError, MultipleUsageActivationError } from './ActivationError'
import PROFILE_CONFIG from './config'

const config = { baseURL: PROFILE_CONFIG.BASE_AUTH_URL, crossDomain: true }
const axios = axiosCore.create(config)


const checkMultipleUsage = async (installVersion: string, key: string) => {
  try {
    // check multiple usage
    const token = localStorage.getItem(PROFILE_CONFIG.LOCAL_STORAGE_TOKEN_VAR)
    if (!token) {
      throw new ActivationError('', 'Cannot get keys without token')
    }
    const config = { headers: { Authorization: token },
      params: {
        productName: PROFILE_CONFIG.PRODUCT_NAME,
        productVersion: installVersion || frontendVersion(),
        forceCache: +new Date(),
      } }
    await axios.get(`/keys/${key}`, config)
  } catch (e) {
    if ((e as AxiosError).response?.status === 405) {
      throw new MultipleUsageActivationError('', 'The maximum number of connections per key has been reached.')
    }
  }
}

export const getToken = (uuid: string) => axios.get<{token: string}>(`/login/${uuid}`, config)
  .then(res => res.data)

export const checkActivation = async (installVersion: string, key: string) => httpClient.get<{
  error?: { title: string; message: string }; edition?: string
}>('/api/activation', { params: { forceCache: +new Date() } })
  .then(async res => {
    const { data } = res
    if (data.error) {
      throw new ActivationError(data.error.title, data.error.message)
    }
    await checkMultipleUsage(installVersion, key)
    startWebSocketPing(key)
    return data
  })

export const getKeys = () => {
  const token = localStorage.getItem(PROFILE_CONFIG.LOCAL_STORAGE_TOKEN_VAR)
  if (!token) {
    throw new Error('Cannot get keys without token')
  }
  const config = { headers: { Authorization: token }, params: { product: PROFILE_CONFIG.PRODUCT_NAME } }
  return axios.get<{keys: Array<{key: string; data: string}>; user: string}>(
    `${PROFILE_CONFIG.BASE_AUTH_URL}/keys`,
    config
  ).then(res => res.data)
}

// WS ping every N seconds for keep key as busy
export function startWebSocketPing(key: string) {
  const token = localStorage.getItem(PROFILE_CONFIG.LOCAL_STORAGE_TOKEN_VAR)
  const client = new WebSocket(Url.format({
    protocol: 'ws',
    hostname: PROFILE_CONFIG.WSS_AUTH_URL,
  }))
  let interval: NodeJS.Timeout

  function sendReq() {
    client.send(JSON.stringify({
      type: typesDef.PING_EVENT,
      authToken: token,
      key,
    }))
  }

  client.onopen = () => {
    sendReq()
    interval = setInterval(sendReq, PROFILE_CONFIG.LICENSE_PING_INTERVAL)
  }

  client.onclose = () => {
    clearInterval(interval)
    setTimeout(() => startWebSocketPing(key), PROFILE_CONFIG.LICENSE_PING_INTERVAL)
  }
}

export const activateKey = async (installVersion: string, key: string) => httpClient
  .post<{ key: string },
   AxiosResponse<{edition: string,
    error?: { message: string; title: string; type: typeof PROFILE_CONFIG.ERROR_TYPE }}>>(
      '/api/activation', { key }
    ).then(async res => {
      if (res.data.error) {
        if (res.data.error.type === 'ACTIVATION_NO_CONNECTION') {
          throw new ConnectionActivationError(res.data.error.title, res.data.error.message)
        } else {
          throw new ActivationError(res.data.error.title, res.data.error.message)
        }
      }

      await checkMultipleUsage(installVersion, key)

      startWebSocketPing(key)

      return res.data
    })

export const addKey = (key: string, edition: string) => {
  const token = localStorage.getItem(PROFILE_CONFIG.LOCAL_STORAGE_TOKEN_VAR)
  if (!token) {
    throw new ActivationError('', 'Cannot get keys without token')
  }
  const config = { headers: { Authorization: token } }

  return axios.post(
    '/keys', { key, product: PROFILE_CONFIG.PRODUCT_NAME, data: edition }, config
  ).then(res => res.data)
}

export const deactivateKey = () => httpClient.delete('/api/activation').then(res => res.data)
