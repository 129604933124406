import { createSelector } from 'reselect'
import { DEFAULT_PROFILE_ID } from 'constant'


export const userProfileSelector = state => state.user

export const hotkeyProfilesSelector = state => state.user.hotkeyProfiles

export const hotkeyProfilesExcludeDefaultSelector = createSelector(
  [ state => state.user.hotkeyProfiles ],
  hotkeyProfiles => {
    const profiles = { ...(hotkeyProfiles?.profiles ?? {}) }
    delete profiles[DEFAULT_PROFILE_ID]
    return {
      ...(hotkeyProfiles ?? {}),
      profiles,
    }
  }
)

export const activeHotkeyProfileIdSelector = state => state.user.hotkeyProfiles.active

export const hotkeyProfilesOptionsSelector = state => {
  const hotkeyProfiles = hotkeyProfilesSelector(state)
  return Object.values(hotkeyProfiles.profiles).map(p => ({ id: p.id, name: p.name }))
}

export const activeHotkeyProfileSelector = state => (
  state.user.hotkeyProfiles.profiles[state.user.hotkeyProfiles.active]
)

export const searchHotkeyTextSelector = state => (
  state.user.searchHotkeyText
)
