import { NW_JS_APP_PROFILES } from 'enums'
import itochu from './itochu'
import desktop from './desktop'

type ConfigType = typeof itochu | typeof desktop
const key = !NW_JS_APP_PROFILES.includes(__APP_PROFILE__ as NwJsProfileType)
  ? NW_JS_APP_PROFILES[0]
  : NW_JS_APP_PROFILES.find(p => p === __APP_PROFILE__)

const INDEX_CONFIG: Record<NwJsProfileType, ConfigType> = { itochu, desktop }
export default INDEX_CONFIG[key as NwJsProfileType]
