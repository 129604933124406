import React, { ReactNode, useCallback, useEffect, useRef } from 'react'
import { NWShortCutContext } from 'contexts/NWShortCutContext'
import { NW_JS_APP_PROFILES } from 'enums'

/** NW.js library  context (for desktop version).
 * Only for NW_JS_APP_PROFILES.
 * Using system keyboard keys.
 */

type Props = {
  children: ReactNode | ReactNode[]
}

export const NWShortCutProvider = ({ children }: Props) => {
  const shortCut = useRef<null | IShortcutConstructor>(null)

  useEffect(() => {
    // The desktop and itochu profile keys registering
    if (NW_JS_APP_PROFILES.includes(__APP_PROFILE__ as NwJsProfileType) && !shortCut.current) {
      shortCut.current = new nw.Shortcut({
        key: 'Escape',
        active() {
          nw.Window.get().leaveFullscreen()
        },
      })
    }
  }, [ ])

  const registerNWGlobalHotKey = useCallback(() => {
    shortCut.current && nw.App.registerGlobalHotKey(shortCut.current)
  }, [])
  const unRegisterNWGlobalHotKey = useCallback(() => {
    shortCut.current && nw.App.unregisterGlobalHotKey(shortCut.current)
  }, [])

  return (
    <NWShortCutContext.Provider value={{
      registerNWGlobalHotKey,
      unRegisterNWGlobalHotKey,
    }}
    >
      {children}
    </NWShortCutContext.Provider>
  )
}
