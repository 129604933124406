import * as ActionTypes from 'actions/ActionTypes'
import * as ActionsLayer from 'actions/layer'
import { VideoAsset } from 'models/Asset'
import Layer from '~/models/Layer'
import * as Selectors from '~/selectors'
import { createAssetFromSourceFile } from './createAssetFromSourceFile'
import { insertLayer } from './insertLayer'

export const addSourceFileAtSliderPosition = id => (dispatch, getState) => {
  const state = getState()
  const { sliderTime } = state.timeline
  const file = Selectors.getSourceFileById(state, id)

  // @link http://18.184.210.86/issues/380
  // Always insert clip at top layer
  const targetLayerIndex = 0
  const targetLayer = Selectors.getLayers(state)[targetLayerIndex]
  const referenceVideo = Selectors.getReferenceVideoAsset(state)

  const assetAtCursor = Selectors.getPlayingAssets(state)
    .find(asset => asset.layerId === targetLayer.id)


  let fitBetweenSliderTimeAndNearestAsset = false
  if (assetAtCursor === undefined) {
    const rightSideAssets = Selectors.getRightSideAssetsBySliderTimeAndLayer(state, targetLayer.id)
    fitBetweenSliderTimeAndNearestAsset = rightSideAssets.length === 0
    || rightSideAssets[0].startTime - sliderTime >= file.duration
  }

  let assetLayerId
  if (assetAtCursor === undefined && fitBetweenSliderTimeAndNearestAsset) {
    assetLayerId = targetLayer.id
  } else {
    const newLayer = new Layer()
    dispatch(insertLayer(targetLayerIndex, newLayer))
    assetLayerId = newLayer.id
  }

  const asset = createAssetFromSourceFile(file, {
    layerId: assetLayerId,
    startTime: sliderTime,
    videoSize: { width: referenceVideo.width, height: referenceVideo.height },
  })

  if (Selectors.getAssets(state).length === 0 && file instanceof VideoAsset) {
    const { width, height, fpsNum, fpsDenum } = file
    dispatch({
      type: ActionTypes.SET_REFERENCE,
      payload: { width, height, fpsNum, fpsDenum, id: file.fileId },
    })
  }

  dispatch(ActionsLayer.addAsset(asset))
}
