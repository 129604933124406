import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { addHotkeyProfile, setHotkey as setHotkeyAction } from 'actions/user'
import { InputEdit } from 'components/InputEdit/InputEdit'
import { CATEGORIZED_HOTKEYS, DEFAULT_PROFILE_ID } from 'constant'
import { TranslationContext } from 'contexts/TranslationContext'
import { EXCLUDE_HOTKEY, HOTKEY_CATEGORY, HOTKEY_SETTINGS_LABELS } from 'enums'
import { useAction } from 'hooks'

import React, { useContext, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { activeHotkeyProfileIdSelector, activeHotkeyProfileSelector, searchHotkeyTextSelector } from 'selectors/user-profile-selector'

export const HotkeysTable = ({ categoryId, onConflict, onChanged }) => {
  const { t } = useContext(TranslationContext)

  const [ conflictHotkey, setConflictHotkey ] = useState('')
  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)
  const activeHotkeyProfileId = useSelector(activeHotkeyProfileIdSelector)
  const searchHotkeyText = useSelector(searchHotkeyTextSelector)
  const addProfile = useAction(addHotkeyProfile)
  const setHotkey = useAction(setHotkeyAction)

  const hotkeyChangedHandler = hotkey => async combination => {
    if (activeHotkeyProfileId === DEFAULT_PROFILE_ID) {
      await addProfile()
    }
    setHotkey(hotkey, combination)
    onChanged(hotkey, combination, activeHotkeyProfile.hotkeys[hotkey])
  }
  const filteredHotkeys = useMemo(() => Object.entries(activeHotkeyProfile.hotkeys)
    .filter(([ hotkey, value ]) => (
      (value !== EXCLUDE_HOTKEY)
      && t(HOTKEY_SETTINGS_LABELS[hotkey])?.toLowerCase().includes(searchHotkeyText.toLowerCase())
    ))
    .filter(
      ([ hotkey ]) => CATEGORIZED_HOTKEYS[hotkey] === categoryId
      || categoryId === HOTKEY_CATEGORY.All
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [ activeHotkeyProfile, categoryId, searchHotkeyText ])
  const handleConflict = combination => {
    const conflictHotkey = Object.entries(activeHotkeyProfile.hotkeys).find(
      ([ _, c ]) => c === combination
    )?.[0]
    setConflictHotkey(conflictHotkey)
    setHotkey(conflictHotkey, '')
    onConflict(conflictHotkey, combination)
  }

  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>{t('COMMAND')}</TableCell>
          <TableCell>{t('SHORTCUTS')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredHotkeys.map(([ hotkey, combination ]) => (
          <TableRow
            key={hotkey}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell>{t(HOTKEY_SETTINGS_LABELS[hotkey])}</TableCell>
            <TableCell
              style={{ position: 'relative', width: '50%' }}
              scope="row"
            >
              {hotkey !== conflictHotkey ? (
                <InputEdit
                  readonly
                  value={combination}
                  conflictValues={Object.values(activeHotkeyProfile.hotkeys).filter(
                    v => v !== combination
                  )}
                  onChanged={hotkeyChangedHandler(hotkey)}
                  onConflict={handleConflict}
                />
              ) : (
                ''
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
