import React, { useContext, useEffect, useState } from 'react'
import Button from 'components/base/Button'
import { InputClearable } from 'components/base/InputClearable/InputClearable'
import { copyToClipboard } from 'Util/clipboard'
import Select from 'components/base/Select'
import MenuItem from 'components/base/MenuItem'
import './styles.scss'
import { CircularProgress } from 'components/base/CircularProgress/CircularProgress'
import { TranslationContext } from 'contexts/TranslationContext'
import PROFILE_CONFIG from '../config'

export type Variant = 'welcome' | 'browserHasOpened' | 'browserHasntOpened' | 'selectKey'

type props = {
  keys: Array<{ key: string; edition: string }> | undefined;
  url: string;
  userEmail?: string;
  onBrowserOpen: () => void;
  onSelectKey: (key: string) => void;
  isLoading?: boolean;
}

export const ActivationVariantsComponent = ({
  onBrowserOpen = () => {}, url = '', keys, onSelectKey = () => {}, userEmail, isLoading,
}: props) => {
  const [ currentVariant, setCurrentVariant ] = useState<Variant>('welcome')
  const [ selectedKey, setSelectedKey ] = useState<string>(() => keys?.[0]?.key || '')
  const { t } = useContext(TranslationContext)

  const handleClickSignIn = () => {
    setCurrentVariant('browserHasOpened')
    onBrowserOpen()
  }

  const handleClickCancel = () => {
    setCurrentVariant('welcome')
  }

  const handleClickCantGetIn = () => {
    setCurrentVariant('browserHasntOpened')
  }

  const handleCopyLink = () => {
    copyToClipboard(url)
  }

  const handleSelectKey = async () => {
    onSelectKey(selectedKey)
  }

  useEffect(() => {
    if (keys?.length) {
      setCurrentVariant('selectKey')
      setSelectedKey(keys[0].key)
    }
  }, [ keys ])

  if (isLoading) {
    return (
      <div className="activation-variant">
        <CircularProgress
          size={100}
          endless
          percents={50}
          transparent
          text={undefined}
          progress={0}
        />
      </div>
    )
  }

  switch (currentVariant) {
    case 'welcome':
      return (
        <div className="activation-variant">
          <div className="activation-variant_header">
            {t('DESKTOP_ACTIVATION_FEATURE_TEXT_LETS_GET_STARTED')}
          </div>
          <Button
            primary
            onClick={handleClickSignIn}
            title={t('DESKTOP_ACTIVATION_FEATURE_BTN_SIGN_UP_OR_LOGIN_TOOLTIP')}
            data-lang-id="DESKTOP_ACTIVATION_FEATURE_BTN_SIGN_UP_OR_LOGIN"
          >
            {t('DESKTOP_ACTIVATION_FEATURE_BTN_SIGN_UP_OR_LOGIN')}
          </Button>
        </div>
      )
    case 'browserHasOpened':
      return (
        <div className="activation-variant">
          <div className="activation-variant_header">
            {t('DESKTOP_ACTIVATION_FEATURE_TEXT_WE_OPENED_BROWSER')}
          </div>
          <div className="activation-variant_description">
            {t('DESKTOP_ACTIVATION_FEATURE_TEXT_COMPLETE_IN_BROWSER')}
          </div>
          <Button border onClick={handleClickCancel}>
            {t('DESKTOP_ACTIVATION_FEATURE_BTN_CANCEL')}
          </Button>
          <div className="activation-variant_link" onClick={handleClickCantGetIn}>
            {t('DESKTOP_ACTIVATION_FEATURE_TEXT_CANT_GET_IN')}
          </div>
        </div>
      )
    case 'browserHasntOpened':
      return (
        <div className="activation-variant">
          <div className="activation-variant_description">
            {t('DESKTOP_ACTIVATION_FEATURE_TEXT_TO_COMPLETE_LOGIN_COPY_URL')}
          </div>
          <InputClearable
            key="url-input"
            placeholder={url}
            onChange={() => {}}
            value={url}
          />
          <Button
            primary
            onClick={handleCopyLink}
            title={t('DESKTOP_ACTIVATION_FEATURE_BTN_COPY_LINK_TOOLTIP')}
            data-lang-id="DESKTOP_ACTIVATION_FEATURE_BTN_COPY_LINK"
          >
            {t('DESKTOP_ACTIVATION_FEATURE_BTN_COPY_LINK')}
          </Button>
          <Button
            border
            onClick={handleClickCancel}
            title={t('DESKTOP_ACTIVATION_FEATURE_BTN_CANCEL_TOOLTIP')}
            data-lang-id="DESKTOP_ACTIVATION_FEATURE_BTN_CANCEL"
          >
            {t('DESKTOP_ACTIVATION_FEATURE_BTN_CANCEL')}
          </Button>
        </div>
      )
    case 'selectKey':
      return keys?.length ? (
        <div className="activation-variant">
          <div className="activation-variant_header">{t('DESKTOP_ACTIVATION_FEATURE_TEXT_WELCOME')}</div>
          <div className="activation-variant_description">
            {t('DESKTOP_ACTIVATION_FEATURE_TEXT_SEVERAL_LICENSES')}
            {' '}
            <br />
            <strong>{userEmail}</strong>
            <br />
            <br />
            {t('DESKTOP_ACTIVATION_FEATURE_TEXT_CHOOSE_LICENSE')}
          </div>
          <Select
            className="activation-variant_select"
            value={selectedKey}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: any) => setSelectedKey(e.target.value)}
          >
            {keys.map(key => (
              <MenuItem key={key.key} value={key.key}>
                {__CFG__.ACTIVATION_BAR_APP}
                {' '}
                {key.edition}
                {' '}
                {t('DESKTOP_ACTIVATION_FEATURE_TEXT_EDITION')}
                {' '}
                {key.key !== PROFILE_CONFIG.FREE_KEY ? `(${key.key})` : ''}
              </MenuItem>
            ))}
          </Select>
          <Button
            primary
            onClick={handleSelectKey}
            title={t('DESKTOP_ACTIVATION_FEATURE_BTN_GET_STARTED_TOOLTIP')}
            data-lang-id="DESKTOP_ACTIVATION_FEATURE_BTN_GET_STARTED"
          >
            {t('DESKTOP_ACTIVATION_FEATURE_BTN_GET_STARTED')}
          </Button>
        </div>
      ) : (
        <>
          {' '}
          {t('DESKTOP_ACTIVATION_FEATURE_TEXT_ERROR_KEYS_NOT_FOUND')}
          {' '}
        </>
      )
  }
  return null
}
