import { DEFAULT_FPS } from '~/Util'
import Asset from './Asset'

/**
 * Represents asset of playable media content – like video or audio.
 *
 *         |<  mediaStart  >|
 *       0 .................. duration
 *         |   MediaAsset   |
 * 0 ............................. Inf
 *   |<       startTime         >|
 *
 * @memberOf Assets
 * @extends {Asset}
 */
class MediaAsset extends Asset {

  constructor(data) {
    if (new.target === MediaAsset) {
      throw new Error(`${new.target.name} can't be instantiated`)
    }

    const {
      mediaStart = 0,
      fpsNum = 0,
      fpsDenum = 0,
      muted = false,
      volume = 1,
      size = 0,
      mediaFileDuration = null,
      offsetLeft = 0,
      offsetRight = 0,
      codecsInfo = null,
      path = null,
      ...rest
    } = data

    super(rest)
    this._fpsNum = fpsNum
    this._fpsDenum = fpsDenum
    this._codecsInfo = codecsInfo
    this._mediaStart = mediaStart
    this._muted = muted
    this._size = size
    this._path = path

    // Assume that initial duration, with which item is created,
    // is duration of associated media file.
    this._mediaFileDuration = mediaFileDuration || this._duration
    // This value is going to change along with `mediaStart`
    // (as if playback starts not from the beginning, clip max duration is less than entire media file)
    this._maxDuration = this._mediaFileDuration
    this._volume = volume

    this._offsetLeft = offsetLeft
    this._offsetRight = offsetRight
  }

  // ---

  /**
   * @returns {number}
   */
  get mediaFileDuration() {
    return this._mediaFileDuration
  }

  /**
   * Playback start position for associated media file.
   *
   * @returns {number}
   */
  get mediaStart() {
    return this._mediaStart
  }

  /**
   * @param {number} time
   */
  set mediaStart(time) {
    this._mediaStart = Math.min(this._mediaFileDuration, Math.max(time, 0))
    // if (this._maxDuration !== Infinity) {
    //   this._maxDuration = this._mediaFileDuration - this._mediaStart
    // }
  }

  /**
   * @returns {number}
   */
  get mediaEnd() {
    return this.mediaStart + this.duration
  }

  /**
 * @return {number}
 */
  get fpsNum() {
    return this._fpsNum || DEFAULT_FPS
  }

  /**
* @return {number}
*/
  get fpsDenum() {
    return this._fpsDenum || 1
  }

  /**
   * @return {number}
   */
  get fps() {
    return this.fpsNum / this.fpsDenum
  }

  /**
   * @return {string}
   */
  get codecsInfo() {
    return this._codecsInfo
  }

  // ---

  /**
   * @returns {number} [0-1]
   */
  get volume() {
    return this._volume
  }

  /**
   * @param {number} volume [0-1]
   */
  set volume(volume) {
    this._volume = volume
  }

  /**
   * @returns {boolean}
   */
  get muted() {
    return this._muted
  }

  /**
   * @param {boolean} muted
   */
  set muted(muted) {
    this._muted = muted
  }

  // ---

  /**
   * @returns {number}
   */
  get size() {
    return this._size
  }

  /**
   * @param {number} size
   */
  set size(size) {
    this._size = size
  }

  // ---

  /**
   * @returns {string}
   */
  get path() {
    return this._path
  }

  /**
   * @param {string} path
   */
  set path(path) {
    this._path = path
  }

  /**
   * @returns {number}
   */
  get offsetLeft() {
    return this._offsetLeft
  }

  /**
   * @param {number} offsetLeft
   */
  set offsetLeft(offsetLeft) {
    this._offsetLeft = offsetLeft
  }

  /**
   * @returns {number}
  */
  get offsetRight() {
    return this._offsetRight
  }

  /**
    * @param {number} offsetRight
  */
  set offsetRight(offsetRight) {
    this._offsetRight = offsetRight
  }

  _truncate(at, delta, leftSide) {
    super._truncate(at, delta, leftSide)
    if (leftSide) {
      this.mediaStart += delta
    }
  }

}

export default MediaAsset
