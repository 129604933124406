import * as ActionTypes from 'actions/ActionTypes'
import PROFILE_CONFIG from 'features/desktop-activation-feature/config'
import produce from 'immer'


/**
 * @typedef {object} App
 * @property {string} edition
 * @property {string} installVersion
 * @property {string} externalLoginUrl

 */

/**
 * @type {App}
 */
const initialState = {
  edition: localStorage.getItem(PROFILE_CONFIG.LOCAL_STORAGE_EDITION_VAR), // desktop only
  installVersion: null,
  externalLoginUrl: null,
}

const app = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.SET_INSTALL_VERSION: {
      const { installVer, externalLoginUrl } = action.payload
      state.installVersion = installVer
      state.externalLoginUrl = externalLoginUrl
      break
    }
    case ActionTypes.SET_EDITION:
      state.edition = action.payload.edition
      break
  }
}

export default produce(app, initialState)
