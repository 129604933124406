import { useContext } from 'react'
import { pickBy } from 'lodash'
import { MEDIA_FILE_FILTERS } from 'enums'
import { TranslationContext } from 'contexts/TranslationContext'

type MediaFileFilterByCFGTType = Partial<Record<keyof typeof MEDIA_FILE_FILTERS, string | boolean>>

export const useMediaFilter = () => {
  const { t } = useContext(TranslationContext)

  // TODO: Remove this func + mediaFileFilterByCFG refactor. Vrspot translit data should be from customLanguage file.
  const getFilterValue = (vrspotValue: string, nonVrspotValue: string = vrspotValue) => __APP_PROFILE__ === 'vrspot'
    ? vrspotValue
    : t(nonVrspotValue)

  const mediaFileFilterByCFG: MediaFileFilterByCFGTType = pickBy({
    All: __CFG__.SOURCE_FILES_FILTERS.ALL
      && getFilterValue('All', 'MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_ALL'),
    Video: __CFG__.SOURCE_FILES_FILTERS.VIDEO
      && getFilterValue('Videos', 'MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VIDEO'),
    Image: __CFG__.SOURCE_FILES_FILTERS.IMAGES
      && getFilterValue('Images', 'MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_IMAGE'),
    Audio: __CFG__.SOURCE_FILES_FILTERS.AUDIO
      && getFilterValue('Audio Files', 'MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_AUDIO'),
    Voiceover: __CFG__.SOURCE_FILES_FILTERS.MEDIA_RECORDING.VOICEOVER
      && __APP_PROFILE__ !== 'vrspot'
      && getFilterValue('Voiceover'),
    Folder: __CFG__.SOURCE_FILES_FILTERS.FOLDERS
      && getFilterValue('Folders', 'MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_FOLDER'),
  }, Boolean)

  return {
    mediaFileFilterByCFG,
  }
}
