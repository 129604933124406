import * as Actions from 'actions'
import { throttle } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { rullerHeight, timebarColor, timebarTextColor, timebarTextFont } from 'styles/variables.scss'
import { AppRoutes } from '~/AppRoutes'
import { getWindowWidth, pixel2Time, refTimeToHHMMSSMSMS, time2Pixel, TIMELINE_RANGE, TIMELINE_TIME_UNIT } from '~/Util'
import { TimelineScrollPositionContext } from './ScrollPositionContext'
import './TimeLineRuller.scss'

const defaultTicksDistance = 128
const defaultTicksRange = 15e7
class TimelineRuller extends React.Component {

  constructor(props) {
    super(props)

    this.canvasRef = React.createRef()

    this.textWidth = 0
    this.textHeight = 0
    this.longTickHeight = 18
    this.shortTickHeight = 8
    this.initialStepTable = []

    // 5ms, 7ms, 9ms, 11ms, 500ms, 1s, 5s, 15s, 30s, 1m, 5m
    this.initialStepTable = [
      5e4, 7e4, 9e4, 11e4, 5e5, 1e6, 5e6, 1e7, 5e7, 15e7, 30e7, 60e7, 5 * 60e7,
    ]
    // TODO: replace it with useLocation()
    if (window.location.pathname === AppRoutes.NewProject) {
      // this.initialTimelinePixWidth = 2625;
      const { setScale, setTimelineDuration } = this.props
      const sectionCount = getWindowWidth() / defaultTicksDistance
      this.initialDuration = sectionCount * defaultTicksRange
      const defaultScale = TIMELINE_RANGE / this.initialDuration
      setScale(defaultScale)
      setTimelineDuration(this.initialDuration)
    }

    // let index = 3;
    // let base = 1e4;
    // while (base < 1e5) {
    //   index += 2;
    //   this.initialStepTable.push(index * base);
    //   if (index >= 10) {
    //     base *= 10;
    //     index = 0;
    //   }
    // }

    // // adding 1s, 3s, 5s, 10s
    // this.initialStepTable = [...this.initialStepTable, 1e7, 3e7, 5e7, 10e7];
    // // adding 15s, 20s, 30s, 40s, 50s
    // this.initialStepTable = [...this.initialStepTable, 15e7, 20e7, 30e7, 40e7, 50e7];
    // // adding 1m, 3m, 5m
    // this.initialStepTable = [...this.initialStepTable, 1 * 60e7, 3 * 60e7, 5 * 60e7];

    // 10 * 60e7, // 10m
    // 15 * 60e7, // 15m
    // 20 * 60e7, // 20m
    // 30 * 60e7, // 30m
    // 40 * 60e7, // 40m
    // 60 * 60e7, // 60m
    // 2 * 60 * 60e7, // 2h
    // 3 * 60 * 60e7, // 3h
    // 4 * 60 * 60e7, // 4h
    // 5 * 60 * 60e7, // 5h
    // 10 * 60 * 60e7, // 10h

    this.updatedTimeArray = []
    this.lastZoomSliderStep = 0

    this.startTime = 0
    // const { sliderTime } = this.props;
    // this.sliderPosRatio = (timelineDuration - sliderTime) / (sliderTime - this.startTime);

    this.onWindowScroll = throttle(event => this.onScroll(event), 50)
    this.onWindowResize = throttle(event => this.onUpdateSize(event), 50)
  }

  render() {
    return (
      <div className="timeline-ruller">
        <canvas className="timeline-canvas" width={getWindowWidth()} height={rullerHeight} ref={this.canvasRef} />
      </div>
    )
  }

  componentDidMount() {
    const { setTimelineDuration, scale } = this.props
    const root = document.getElementById('root-app-solveig')

    window.addEventListener('resize', this.onWindowResize)
    root.addEventListener('scroll', this.onWindowScroll)
    this.initMinTextWidth()

    this.initTimeArray()
    this.initZoomSliderLastStepIndex()
    this.drawRuller()

    this.initialOffsetLeft = this.canvasRef.current.offsetLeft
    this.initialVisibleTimeRange = pixel2Time(
      getWindowWidth() - this.initialOffsetLeft, scale
    )
    const duration = this.startTime + this.initialVisibleTimeRange
    setTimelineDuration(duration)
  }

  componentDidUpdate(prevProps) {
    const { timelineScrollLeft, scale, setTimelineDuration } = this.props
    this.startTime = pixel2Time(timelineScrollLeft, scale)
    this.drawRuller()

    if (prevProps.scale !== scale || prevProps.timelineScrollLeft !== timelineScrollLeft) {
      this.initialVisibleTimeRange = pixel2Time(
        getWindowWidth() - this.initialOffsetLeft, scale
      )
      const duration = this.startTime + this.initialVisibleTimeRange
      setTimelineDuration(duration)

      // if (duration > this.initialDuration) {
      // } else {
      //   setTimelineDuration(this.initialDuration)
      // }
    }

    // const { sliderTime, scale } = this.props;
    // if (sliderTime !== prevProps.sliderTime) {
    //   const range = this.getVisibleTimelineRange();
    //   this.sliderPosRatio = (range - sliderTime) / sliderTime;
    //   const rigthRange = range - sliderTime;
    //   const leftRange = rigthRange / this.sliderPosRatio;
    //   this.startTime = range - rigthRange - leftRange;
    // }
    // if (scale !== prevProps.scale) {
    //   const range = this.getVisibleTimelineRange();
    //   const rigthRange = range - sliderTime;
    //   const leftRange = rigthRange / this.sliderPosRatio;
    //   this.startTime = range - rigthRange - leftRange;
    // }
  }

  componentWillUnmount() {
    const root = document.getElementById('root-app-solveig')
    window.removeEventListener('resize', this.onWindowResize)
    root.removeEventListener('scroll', this.onWindowScroll)
  }

  onUpdateSize = () => {
    // TODO: make drawRuller as a handler if nothing else here.
    this.drawRuller()
  }

  onScroll = event => {
    const { scale } = this.props
    this.startTime = pixel2Time(event.target.scrollLeft, scale)
    this.drawRuller()
  }

  getInitialZoomState() {
    const step = this.getCurrentTimeLineStep()
    const zoomindex = this.lastZoomSliderStep - this.getStepIndex(step)
    return { min: 1, max: this.lastZoomSliderStep, value: zoomindex }
  }

  getStepByZoomIndex(zoomindex) {
    const index = this.lastZoomSliderStep - zoomindex
    return this.updatedTimeArray[index]
  }

  getStepIndex(step) {
    let index = 0
    for (let i = 0; i < this.updatedTimeArray.length; i++) {
      if (this.updatedTimeArray[i] > step) {
        index = i - 1
        break
      }
    }
    return index
  }

  getVisibleTimelineRange() {
    // (this.initialTimelinePixWidth) - TIMELINE_RANGE
    // canvas width          -   x
    const canvasWidth = this.canvasRef.current.width
    const { scale } = this.props
    return ((TIMELINE_RANGE * canvasWidth) / getWindowWidth()) / scale
  }

  getInitialTimelineStep() {
    const numOfEntries = getWindowWidth() / this.textWidth
    const aproxTimelineStep = TIMELINE_RANGE / numOfEntries
    const step = this.getTemplateTimeLineTimeStap(aproxTimelineStep)
    return step
  }

  getApproximateTimeLineStep() {
    const { scale } = this.props
    const fullW = scale * getWindowWidth()
    const numOfEntries = fullW / this.textWidth
    const ret = TIMELINE_RANGE / numOfEntries
    return ret
  }

  getTemplateTimeLineTimeStap(tStep) {
    const xs = this.updatedTimeArray
    if (xs.length === 0) {
      return 0
    }

    return xs.find(x => tStep <= x) || xs[xs.length - 1]
  }

  getCurrentTimeLineStep() {
    const aproxStep = this.getApproximateTimeLineStep()
    const correctedSecStep = this.getTemplateTimeLineTimeStap(aproxStep)
    return correctedSecStep
  }

  initMinTextWidth() {
    const ctx = this.canvasRef.current.getContext('2d')
    ctx.font = timebarTextFont
    const textSize = ctx.measureText('00:00:00,000')
    this.textWidth = Math.ceil(textSize.width) * 2
    this.textHeight = Math.ceil(textSize.actualBoundingBoxAscent) + 1
  }

  initTimeArray() {
    this.updatedTimeArray = this.initialStepTable.slice()
  }

  initZoomSliderLastStepIndex() {
    this.lastZoomSliderStep = this.updatedTimeArray.length
    const step = this.getCurrentTimeLineStep()
    for (let i = 0; i < this.updatedTimeArray.length; i++) {
      if (this.updatedTimeArray[i] <= step) {
        this.lastZoomSliderStep = i
      }
    }
    this.lastZoomSliderStep += 1
  }

  drawRuller() {
    const cs = getComputedStyle(this.canvasRef.current)
    const width = getWindowWidth() // parseInt(cs.getPropertyValue('width'), 10);
    const height = parseInt(cs.getPropertyValue('height'))
    this.canvasRef.current.width = width
    this.canvasRef.current.height = height
    const { scale } = this.props

    const ctx = this.canvasRef.current.getContext('2d')
    ctx.translate(0.5, -0.5)
    ctx.clearRect(0, 0, width, height)

    ctx.lineWidth = 1
    ctx.lineCap = 'square'
    ctx.beginPath()
    ctx.moveTo(0, height)
    ctx.lineTo(width, height)
    ctx.strokeStyle = timebarColor
    ctx.stroke()

    const correctedSecStep = this.getCurrentTimeLineStep()

    let smallTickNubmer = 15
    if (correctedSecStep < 2e7) {
      smallTickNubmer = 5
    } else if (correctedSecStep < 6e7) {
      smallTickNubmer = 10
    }

    const correctedSecStepSmall = correctedSecStep / smallTickNubmer
    let curTickPos = 0

    const stopPosX = width
    let drawBigTick = true
    let bigCounter = 0

    // console.log(`scroll left ${this.scrollLeft}, draw till ${stopPosX}`);

    while (curTickPos < 50 * 60 * 60 * TIMELINE_TIME_UNIT) {
      const tickPosPix = time2Pixel(curTickPos, scale)
      if (tickPosPix > stopPosX) {
        // console.log(`---last tick pos ${tickPosPix}`);
        break
      }
      if (drawBigTick) {
        drawBigTick = false
        bigCounter = 0
        ctx.lineWidth = 1
        ctx.lineCap = 'square'
        ctx.beginPath()
        ctx.moveTo(tickPosPix, height)
        ctx.lineTo(tickPosPix, height - this.longTickHeight)
        ctx.strokeStyle = timebarColor

        ctx.stroke()

        ctx.fillStyle = timebarTextColor
        ctx.font = timebarTextFont
        const timeStr = refTimeToHHMMSSMSMS(curTickPos + this.startTime)
        ctx.fillText(timeStr, tickPosPix + 2, (height - this.longTickHeight) + this.textHeight / 2)
      } else {
        ctx.lineWidth = 1
        ctx.lineCap = 'square'
        ctx.beginPath()
        ctx.moveTo(tickPosPix, height)
        ctx.lineTo(tickPosPix, height - this.shortTickHeight)
        ctx.strokeStyle = timebarColor
        ctx.stroke()
      }

      bigCounter += 1
      curTickPos += correctedSecStepSmall

      if (correctedSecStep < 2e7) {
        drawBigTick = bigCounter === 5
      } else if (correctedSecStep < 6e7) {
        drawBigTick = bigCounter === 10
      } else {
        drawBigTick = bigCounter === 15
      }
    }
  }

}

const mapStateToProps = state => ({
  scale: state.timeline.scale,
  sliderTime: state.timeline.sliderTime,
  // range: state.timeline.range,
})

const mapDispatchToProps = dispatch => ({
  setScale: scale => dispatch(Actions.timeline.setScale(scale)),
  setTimelineDuration: duration => dispatch(Actions.timeline.setDuration(duration)),
})

export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const { scrollLeft } = React.useContext(TimelineScrollPositionContext)
  return (
    <TimelineRuller {...props} timelineScrollLeft={scrollLeft} />
  )
})
