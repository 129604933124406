import * as ActionTypes from 'actions/ActionTypes'
import Layer from '~/models/Layer'

/**
 * @param {number} index
 * @param {TimelineLayer} [layer]
 */
export const insertLayer = (
  index, layer = new Layer(), isHovering = false, isAdditional = false
) => ({
  type: ActionTypes.INSERT_LAYER,
  payload: { index, layer, isHovering, isAdditional },
})
