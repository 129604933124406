import React, { useCallback, useEffect, useState } from 'react'
import { ContextStateType, IContextProps, ProjectMenuContext } from 'contexts/ProjectContext'

export const ProjectContextMenuProvider = ({ children }: IContextProps) => {
  const initialContextMenuData = { projectId: '', visible: false, top: 0, left: 0 }
  const [ contextMenu, setContextMenu ] = useState<ContextStateType>({ ...initialContextMenuData })

  const handleContextMenu = useCallback((e: MouseEvent | KeyboardEvent | null, data?: ContextStateType) => {
    if (e?.type === 'keydown' && (e as KeyboardEvent)?.code !== 'Escape') {
      return
    }
    setContextMenu(data ?? { ...initialContextMenuData })
  }, [ setContextMenu, initialContextMenuData ])

  useEffect(() => {
    document.removeEventListener('click', handleContextMenu)
    document.removeEventListener('keydown', handleContextMenu)
    document.addEventListener('click', handleContextMenu)
    document.addEventListener('keydown', handleContextMenu)

    return () => {
      document.removeEventListener('click', handleContextMenu)
      document.removeEventListener('keydown', handleContextMenu)
    }
  }, [ handleContextMenu ])

  return (
    <ProjectMenuContext.Provider value={{
      contextMenu,
      handleContextMenu,
    }}
    >
      {children}
    </ProjectMenuContext.Provider>
  )
}
