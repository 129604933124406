/* eslint-disable no-await-in-loop */
import { saveProject } from '~/actions/projectData/saveProject'
import { continiousChangingSettingsDebounceMs } from '~/constant'
import {
  selectCurrentHistoryAction, selectIsIgnoreLatestActionInHistory,
  selectIsLatestActionIsHistory,
  selectIsPointerAtFirstAction,
  selectIsPointerAtLastAction,
  selectNextHistoryAction,
  selectIsLockedHistory
} from '~/selectors/historyActions'
import { selectActiveProjectLoading } from '~/selectors/projectData'
import { HistoryActionsService } from '~/services/HistoryActionsService'
import { getObjectFields, isObject } from '~/Util'
import * as ActionTypes from './ActionTypes'

export const patchHistoryAction = payload => dispatch => {
  dispatch({ type: ActionTypes.ACTIONS_PATCH_HISTORY_ACTION, payload })
}

const historyActionErrorHandler = dispatch => () => {
  dispatch({ type: ActionTypes.ACTIONS_HISTORY_ERROR, payload: 'Unable to save the action to history' })
}

const syncHistoryAction = (historyAction, dispatch) => {
  const getPreparedAction = () => {
    const clearedAction = getObjectFields(historyAction.getOptimizedObject())
    delete clearedAction.payload
    const preparedToSavingAction = { ...Object.fromEntries(Object.keys(clearedAction)
      .filter(k => clearedAction[k] !== null && clearedAction[k] !== undefined)
      .map(k => [ k, isObject(clearedAction[k]) ? getObjectFields(clearedAction[k])
        : clearedAction[k] ])) }
    return preparedToSavingAction
  }

  // NOTE: This is for continuously updated actions (e.g. opacity slider, asset volume slider, asset expanding on timeline)
  function saveAfterUpdates() {
    setTimeout(() => {
      const { modifTime } = historyAction
      if (Date.now() - modifTime >= continiousChangingSettingsDebounceMs) {
        HistoryActionsService.saveAction(
          getPreparedAction(),
          historyActionErrorHandler(dispatch)
        )
      } else saveAfterUpdates()
    }, continiousChangingSettingsDebounceMs)
  }

  if (historyAction.isContinuously()) {
    saveAfterUpdates()
  } else {
    HistoryActionsService.saveAction(
      getPreparedAction(),
      historyActionErrorHandler(dispatch)
    )
  }
}

const createSync = async (
  { historyAction, dispatch }
) => {
  if (__APP_PROFILE__ !== 'package') {
    await dispatch(saveProject())
  }

  syncHistoryAction(historyAction, dispatch)
}

export const updateHistoryAction = payload => (dispatch, getState) => {
  // don't use this deprecated http://18.184.210.86/issues/3844#note-10
  const isIgnoreLatestActionInHistory = selectIsIgnoreLatestActionInHistory(getState())
  if (!isIgnoreLatestActionInHistory) {
    if (payload) {
      dispatch(patchHistoryAction(payload))
    }
  }
}

export const addHistoryAction = (
  HistoryAction,
  payload,
  shouldRefresh = false,
  shiftIndex = 0,
  isRestoringAction = false,
  sync = true,
  ignoreActionIndex = false
) => async (dispatch, getState) => {
  const historyAction = new HistoryAction(payload, dispatch, getState)
  const state = getState()
  const isLatestActionIsHistory = selectIsLatestActionIsHistory(state)
  const isActiveProjectLoading = selectActiveProjectLoading(state)
  const isIgnoreLatestActionInHistory = selectIsIgnoreLatestActionInHistory(state)
  const isLockedHistory = selectIsLockedHistory(state)
  if (!isLatestActionIsHistory && !isIgnoreLatestActionInHistory && !isLockedHistory
    && ((!isActiveProjectLoading) || isRestoringAction)) {
    dispatch({ type: ActionTypes.ACTIONS_ADD_HISTORY_ACTION,
      payload: { historyAction, shiftIndex, ignoreActionIndex, sync } })
    if (sync) {
      createSync(
        { historyAction, dispatch }
      )
    }
  } else if (shouldRefresh) {
    dispatch(patchHistoryAction(payload))
  }
}

export const historyUndo = (
  sync = true
) => async (dispatch, getState) => {
  let nextHistoryAction
  const state = getState()
  const isPointerAtFirstAction = selectIsPointerAtFirstAction(state)
  const isLatestActionIsHistory = selectIsLatestActionIsHistory(state)
  const isLockedHistory = selectIsLockedHistory(state)
  if (!isLatestActionIsHistory && !isLockedHistory) {
    await dispatch({ type: ActionTypes.ACTIONS_UNDO })
    if (!isPointerAtFirstAction) {
      nextHistoryAction = selectNextHistoryAction(getState())
      if (nextHistoryAction) {
        const undoActionsGenerator = nextHistoryAction.undo()
        for (const action of undoActionsGenerator) {
          // eslint-disable-next-line no-await-in-loop
          await dispatch(action)
        }
        await dispatch({ type: ActionTypes.ACTIONS_CLEAR_IGNORE_HISTORY })
        if (sync) {
          HistoryActionsService.undoAction(nextHistoryAction.id)
        }
      }
    }
    await dispatch({ type: ActionTypes.ACTIONS_UNLOCK_ACTIONS_HISTORY })
    if (nextHistoryAction?.isTransit) {
      dispatch(historyUndo())
    }
  }
}

export const historyRedo = (isRestoringAction = false, sync = true) => async (
  dispatch, getState
) => {
  let nextHistoryAction
  const state = getState()
  const isPointerAtLastAction = selectIsPointerAtLastAction(state)
  const isLatestActionIsHistory = selectIsLatestActionIsHistory(state)
  const isLockedHistory = selectIsLockedHistory(state)
  if (!isLatestActionIsHistory && !isLockedHistory) {
    await dispatch({ type: ActionTypes.ACTIONS_REDO })
    if (!isPointerAtLastAction || isRestoringAction) {
      const currentHistoryAction = selectCurrentHistoryAction(getState())
      nextHistoryAction = selectNextHistoryAction(getState())
      if (currentHistoryAction) {
        const undoActionsGenerator = currentHistoryAction.redo()
        for (const action of undoActionsGenerator) {
          // eslint-disable-next-line no-await-in-loop
          await dispatch(action)
        }
        await dispatch({ type: ActionTypes.ACTIONS_CLEAR_IGNORE_HISTORY })
        if (sync) {
          HistoryActionsService.redoAction(currentHistoryAction.id)
        }
      }
    }
    await dispatch({ type: ActionTypes.ACTIONS_UNLOCK_ACTIONS_HISTORY })
    if (nextHistoryAction?.isTransit) {
      dispatch(historyRedo())
    }
  }
}

export const clearActionsHistory = () => ({ type: ActionTypes.ACTIONS_CLEAR_ACTIONS_HISTORY })

export const clearActionsHistoryError = () => dispatch => {
  dispatch({ type: ActionTypes.ACTIONS_HISTORY_ERROR })
}

export const setBlockAddHistoryAction = block => dispatch => {
  dispatch({ type: ActionTypes.ACTIONS_SET_BLOCK_ADD_HISTORY_ACTION,
    payload: { block } })
}
