import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from 'actions'
import { setSourceFilesParams } from 'actions/sourceFiles'
import { IMAGES_SOURCES, SOURCE_FILE_TYPES } from 'enums'
import { selectSourceFilesParams } from 'selectors'
import { isEmpty } from 'lodash'
import { createWebSocketClient } from './ServerAPI'
import * as AuthToken from './cookieServices/AuthToken'
import MessageBox from './components/base/MessageBox'
import { onlineTestCooldownMs } from '~/constant'
import * as ActionTypes from '~/actions/ActionTypes'


export const typesDef = {
  USER_EVENT: 'user_event',
  PING_EVENT: 'ping',
  PONG_EVENT: 'pong',
  USER_INFO_EVENT: 'user_info',
}

class WebSocket extends Component {

  state = { showAuthTokenMessage: false }

  render() {
    const { showAuthTokenMessage } = this.state
    const { userError } = this.props
    return (
      <>
        <If condition={showAuthTokenMessage}>
          <MessageBox
            message={__CFG__.CUSTOM.AUTH_TOKEN_MISSED_MESSAGE}
            onClose={() => { window.open(__CFG__.CUSTOM.BACKURL, '_top') }}
          />
        </If>
        <If condition={userError}>
          <MessageBox
            title={userError.title}
            message={userError.message}
            onClose={() => { window.location.reload() }}
          />
        </If>
      </>
    )
  }

  componentDidMount() {
    this.createWebSocketInstance()
  }

  componentWillUnmount() {
    if (!this.client) return
    this.client.close()
    this.client = null
  }

  createWebSocketInstance = () => {
    const client = createWebSocketClient()
    this.client = client
    client.onopen = () => {
      const authToken = AuthToken.get()
      // http://18.184.210.86/issues/3769#note-15
      const canPlayHevc = !!document.createElement('video').canPlayType('video/mp4; codecs="hvc1.1.6.L93.90"')
      this.setState({ showAuthTokenMessage: authToken === undefined })
      client.send(JSON.stringify({
        type: typesDef.USER_EVENT,
        payload: { authToken, canPlayHevc },
      }))
      if (this.showOfflineTimeout) {
        clearTimeout(this.showOfflineTimeout)
        this.showOfflineTimeout = null
      }
      const { isOffline } = this.props
      if (isOffline) {
        this.showOfflineModePlug(false)
      }
    }
    client.onmessage = message => {
      const dataFromServer = JSON.parse(message.data)
      if (dataFromServer.type === typesDef.PING_EVENT) {
        client.send(JSON.stringify({
          type: typesDef.PONG_EVENT,
        }))
      } else if (dataFromServer.type === typesDef.USER_INFO_EVENT) {
        this.updateUserProfile(dataFromServer.payload)
      }
    }
    client.onclose = () => {
      this.createWebSocketInstance()
      if (!this.showOfflineTimeout) {
        this.showOfflineTimeout = setTimeout(this.showOfflineModePlug.bind(this),
          onlineTestCooldownMs, true)
      }
    }
  }

  showOfflineModePlug = isOffline => {
    const { dispatch } = this.props
    dispatch({ type: ActionTypes.SET_IS_OFFLINE, payload: { isOffline } })
  }

  updateUserProfile = profile => {
    const { dispatch } = this.props
    dispatch(Actions.user.updateUserProfile(profile))
    const { imageLibSources } = profile
    const { source } = this.props
    if (__CFG__.SOURCE_FILES_TYPES.IMAGES) {
      dispatch(setSourceFilesParams(SOURCE_FILE_TYPES.IMAGES,
        {
          imageLibSources,
          // eslint-disable-next-line no-nested-ternary
          source: imageLibSources.includes(source) ? source
            : !isEmpty(imageLibSources) ? imageLibSources[0] : IMAGES_SOURCES.Native,
        }))
    }
  }

}

const mapDispatchToProps = dispatch => ({
  dispatch,
})


const mapStateToProps = state => ({ isOffline: state.mainView.isOffline,
  source: selectSourceFilesParams(state, SOURCE_FILE_TYPES.IMAGES).source,
  userError: state.user.error })

export default connect(mapStateToProps, mapDispatchToProps)(WebSocket)
