/* eslint-disable require-yield */
import { all, put, select, takeEvery } from 'redux-saga/effects'
import * as ActionTypes from '~/actions/ActionTypes'
import {
  setIsSliderOnBufferingPos,
  setTimelineAssetIds,
  deleteLayer,
  forceDeleteEmptyLayer
} from '~/actions/timeline'
import { getBackgroundAssets } from '~/helpers/getBackgroundAssets'
import { getComposedAssets } from '~/helpers/getComposedAssets'
import { getPlayingAssets } from '~/helpers/getPlayingAssets'
import { getTransitionAssets } from '~/helpers/getTransitionAssets'
import {
  getAssets,
  getAssetsByLayers,
  getTransitionsByAssetId,
  selectBackgroundAssetIds,
  selectComposedAssetIds,
  selectCurrentPreviewAsset,
  selectFlattenedVideoAssets,
  selectPlayingAssetIds,
  selectTransitionAssetIds
} from '~/selectors'
import { selectIsIgnoreLatestActionInHistory } from '~/selectors/historyActions'

import { secondsToTimelineTime } from '~/Util'

const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b)
const toId = x => x.id

function* handleSliderUpdateOrRewind() {
  const sliderTime = yield select(state => state.timeline.sliderTime)
  const allAssets = yield select(getAssets)
  const flattenedVideoAssets = yield select(selectFlattenedVideoAssets)
  const assetsByLayers = yield select(getAssetsByLayers)
  const backgroundAssetIds = yield select(selectBackgroundAssetIds)
  const transitionAssetIds = yield select(selectTransitionAssetIds)
  const composedAssetIds = yield select(selectComposedAssetIds)
  const playingAssetIds = yield select(selectPlayingAssetIds)

  const newPlayingAssets = getPlayingAssets({ sliderTime, assetsByLayers })
  const newPlayingAssetIds = newPlayingAssets.map(toId)

  const transitions = yield select(state => (
    newPlayingAssetIds.slice().reverse().reduce((acc, id) => {
      const assetTransitions = getTransitionsByAssetId(state, id)
      return assetTransitions.length ? [ ...acc, ...assetTransitions ] : acc
    }, [])
  ))

  const newTransitions = getTransitionAssets({
    sliderTime,
    transitions,
  })

  const newTransitionAssetIds = newTransitions.map(toId)

  const newComposedAssetIds = getComposedAssets({
    allAssets,
    transitions: newTransitions,
    playingMediaAssetIds: newPlayingAssetIds,
  }).map(toId)

  const newBackgroundAssetIds = getBackgroundAssets({
    sliderTime,
    playingMediaAssetIds: newPlayingAssetIds,
    flattenedVideoAssets,
  }).map(toId)

  if (!isEqual(backgroundAssetIds, newBackgroundAssetIds)
  || !isEqual(playingAssetIds, newPlayingAssetIds)
  || !isEqual(transitionAssetIds, newTransitionAssetIds)
  || !isEqual(composedAssetIds, newComposedAssetIds)) {
    yield put(setTimelineAssetIds({
      playingAssetIds: newPlayingAssetIds,
      backgroundAssetIds: newBackgroundAssetIds,
      composedAssetIds: newComposedAssetIds,
      transitionAssetIds: newTransitionAssetIds,
    }))
  }
}

function* handleSliderUpdate({ payload }) {
  const { time: sliderTime } = payload
  const currentPreviewAsset = yield select(selectCurrentPreviewAsset)
  const isSliderOnBufferingPos = yield select(state => state.timeline.isSliderOnBufferingPos)
  if (currentPreviewAsset) {
    if (currentPreviewAsset.endTime - sliderTime <= secondsToTimelineTime(0.3)) {
      if (!isSliderOnBufferingPos) {
        yield put(setIsSliderOnBufferingPos(true))
      }
    } else if (isSliderOnBufferingPos) {
      yield put(setIsSliderOnBufferingPos(false))
    }
  }
}

function* deleteEmptyLayers({ payload }) {
  const { recordInHistory } = payload ?? {}
  const layers = yield select(state => state.timeline.layers)
  const ignoreLatestActionInHistory = yield select(selectIsIgnoreLatestActionInHistory)
  const assetsByLayers = yield select(getAssetsByLayers)

  if (ignoreLatestActionInHistory) return

  for (const [ index, layer ] of layers.entries()) {
    const assets = assetsByLayers.get(layer.id)
    if (assets.length === 0 && index !== layers.length - 1) {
      yield put(deleteLayer(layer.id, false, true, recordInHistory))
    }
  }
}

function* deleteEmptyLayersWithoutHistory() {
  const layers = yield select(state => state.timeline.layers)
  const assetsByLayers = yield select(getAssetsByLayers)

  const ids = []
  for (const layer of layers.values()) {
    const assets = assetsByLayers.get(layer.id)
    if (assets.length === 0) {
      ids.push(layer.id)
    }
  }
  yield put(forceDeleteEmptyLayer(ids))
}

function* watchAll() {
  yield all([
    takeEvery([
      ActionTypes.TIMELINE_REWINDED,
      ActionTypes.UPDATE_SLIDER,
      ActionTypes.TRANSITION_ATTACHED_TO_ASSET,
      ActionTypes.DELETE_ASSETS,
      ActionTypes.RIPPLE_DELETE_ASSETS,
      ActionTypes.RIPPLE_TRIM,
      ActionTypes.UNDO_RIPPLE_TRIM,
      ActionTypes.ASSET_MOVED_ON_TIMELINE,
      ActionTypes.ASSET_ADDED_TO_TIMELINE,
      ActionTypes.COPIED_ASSET_ADDED_TO_TIMELINE,
      ActionTypes.ASSET_PREVIEW_UPDATED,
      ActionTypes.LAYER_TOGGLE_VISIBLE ], handleSliderUpdateOrRewind),
    takeEvery([ ActionTypes.UPDATE_SLIDER ], handleSliderUpdate),
    takeEvery([
      ActionTypes.ASSET_MOVED_ON_TIMELINE,
      ActionTypes.DELETE_ASSETS,
      ActionTypes.RIPPLE_DELETE_ASSETS,
      ActionTypes.RIPPLE_TRIM,
    ], deleteEmptyLayers),
    takeEvery([
      ActionTypes.MULTIPLE_ASSETS_MOVED_ON_TIMELINE,
      ActionTypes.UNDO_MULTIPLE_MOVE_ASSETS,
    ], deleteEmptyLayersWithoutHistory),
  ])
}

export default watchAll
