/* eslint-disable react/no-danger */
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

export const SvgInline = ({ url, className, style }) => {
  const [ svg, setSvg ] = useState(null)
  const [ isLoaded, setIsLoaded ] = useState(false)
  const [ isErrored, setIsErrored ] = useState(false)

  useEffect(() => {
    // fix: Can't perform a React state update on an unmounted component
    let isMounted = true
    const abortController = new AbortController()
    fetch(url, { signal: abortController.signal })
      .then(res => res.text())
      .then(svg => isMounted && setSvg(svg))
      .catch(error => isMounted && setIsErrored(error))
      .then(() => isMounted && setIsLoaded(true))
    return () => {
      abortController.abort()
      isMounted = false
    }
  }, [ url ])

  return (
    <div
      style={style}
      className={classNames(className, `svgInline svgInline--${isLoaded ? 'loaded' : 'loading'} ${isErrored ? 'svgInline--errored' : ''}`)}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  )
}
