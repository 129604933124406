import { useContext } from 'react'
import { TranslationContext } from 'contexts/TranslationContext'
import { format, differenceInHours, differenceInMinutes } from 'date-fns'
import * as locale from 'date-fns/locale'

export const FormattedLatestDate = ({ date = new Date() }) => {
  const dateObj = new Date(date)
  let timeAgo = differenceInHours(new Date(), dateObj)
  const { t } = useContext(TranslationContext)

  let formatted = format(dateObj, 'P p', { locale: locale[navigator.language.replace('-', '') || navigator.userLanguage] })
  if (timeAgo < 24) {
    if (timeAgo >= 1) {
      formatted = t('PROJECTS_POINT_TIME_MEASUREMENT_HOURS_AGO')
    } else {
      timeAgo = differenceInMinutes(new Date(), dateObj)
      formatted = timeAgo === 0
        ? t('PROJECTS_POINT_TIME_MEASUREMENT_JUST_NOW')
        : t('PROJECTS_POINT_TIME_MEASUREMENT_MINUTES_AGO')
    }
  }
  return formatted.replace('%d', timeAgo).trim()
}
