import cx from 'classnames'

import * as Actions from 'actions'
import MediaRecordingCombobox from 'components/Timeline/components/MediaRecordingCombobox'
import { InputRename } from 'components/base/InputRename/InputRename'
import RawButton from 'components/base/RawButton'
import { HOTKEYS } from 'enums'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useDispatch, useSelector } from 'react-redux'
import * as Selectors from 'selectors'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import { rippleDelete as rippleDeleteAction } from 'actions/timeline'
import { useHotkeyTooltip } from 'hooks/useHotkeyTooltip'
import { HHMMSSMSMS2TimelineTime, refTimeToHHMMSSMSMS } from '~/Util'
import { historyRedo as historyRedoAction, historyUndo as historyUndoAction } from '~/actions/historyActions'
import { assetsPaste } from '~/actions/assetsPaste'
import { ReactComponent as CheckIcon } from '~/assets/timeline/ic_check.svg'
import { ReactComponent as CutIcon } from '~/assets/timeline/ic_cut.svg'
import { ReactComponent as NextIcon } from '~/assets/timeline/ic_redo.svg'
import { ReactComponent as SettingsIcon } from '~/assets/timeline/ic_settings.svg'
import { ReactComponent as PrevIcon } from '~/assets/timeline/ic_undo.svg'
import { ReactComponent as CopyIcon } from '~/assets/timeline/ic_copy.svg'
import { ReactComponent as PasteIcon } from '~/assets/timeline/ic_paste.svg'
import { useAction } from '~/hooks'
import { PlaceholderAsset } from '~/models/Asset'
import { selectIsPointerAtFirstAction, selectIsPointerAtLastAction } from '~/selectors/historyActions'

import { TranslationContext } from '~/contexts/TranslationContext'
import './Toolbar.scss'
import { selectCopiedAssets } from '~/selectors/select-copied-assets'


export default function TimelineToolbar({ disabled }) {
  const dispatch = useDispatch()
  const split = useAction(Actions.layer.split, null)
  const splitRippleNext = useAction(Actions.layer.rippleTrim, { ripple: 'next' })
  const splitRipplePrev = useAction(Actions.layer.rippleTrim, { ripple: 'prev' })
  const deleteAssets = useAction(Actions.timeline.deleteSelectedAssets)
  const historyUndo = useAction(historyUndoAction)
  const historyRedo = useAction(historyRedoAction)
  const rewind = useAction(Actions.timeline.rewind)
  const rippleDelete = useAction(rippleDeleteAction)
  const copyAssets = useAction(Actions.layer.assetsCopy)
  const pasteAssets = useAction(assetsPaste)

  const assets = useSelector(Selectors.getAssets)
  const isSliderTimeIntoTransition = useSelector(Selectors.selectIsSliderTimeIntoTransition)
  const copiedAssets = useSelector(selectCopiedAssets)
  const sliderTime = useSelector(state => state.timeline.sliderTime)
  const isPointerAtFirstAction = useSelector(selectIsPointerAtFirstAction)
  const isPointerAtLastAction = useSelector(selectIsPointerAtLastAction)
  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)
  const isSliderIntersectSelectedAssets = useSelector(Selectors
    .isSliderIntersectSelectedAssets)

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.CUT_CLIPS], split)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.RIPPLE_TRIM_NEXT], splitRippleNext)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.RIPPLE_TRIM_PREV], splitRipplePrev)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.RIPPLE_DELETE], () => rippleDelete())

  const timeInputRef = useRef()

  const selectedAssets = assets.filter(el => el.selected && !(el instanceof PlaceholderAsset))
  const onSettingsClicked = React.useCallback(() => {
    const asset = selectedAssets[0]
    dispatch(Actions.mainView.openAssetSettingForm(asset.id))
  }, [ dispatch, selectedAssets ])

  const isSingleAssetSelected = selectedAssets.length === 1
  const cutButtonIsDisabled = isSliderTimeIntoTransition
  || !selectedAssets.length || !isSliderIntersectSelectedAssets
  const isUndoAvailable = !isPointerAtFirstAction
  const isRedoAvailable = !isPointerAtLastAction
  const isSelectedAssets = selectedAssets.length
  const isCopiedAssets = copiedAssets.length

  const onClickUndo = () => {
    if (isUndoAvailable) {
      historyUndo()
    }
  }
  const onClickRedo = () => {
    if (isRedoAvailable) {
      historyRedo()
    }
  }

  const [ timeValue, setTimeValue ] = useState(0)

  useEffect(() => {
    setTimeValue(refTimeToHHMMSSMSMS(sliderTime))
  }, [ sliderTime ])

  const handleChangeTime = e => {
    const value = HHMMSSMSMS2TimelineTime(e)
    if (!isNaN(value)) {
      rewind(value)
    } else {
      setTimeValue(0)
      setTimeout(() => {
        setTimeValue(refTimeToHHMMSSMSMS(sliderTime))
      })
    }
  }

  const { t } = useContext(TranslationContext)
  const { getTooltip } = useHotkeyTooltip()

  return (
    <If condition={__CFG__.TIMELINE.TOOLBAR}>
      <div className={cx('timeline-toolbar', { 'timeline-toolbar--disabled': disabled })}>
        <div className="timeline-toolbar__left">
          <div className="timeline-toolbar__undo-redo">
            <div className="timeline-toolbar__history-item" onClick={onClickUndo} title={`${getTooltip(t('TIMELINE_TOOLBAR_BTN_UNDO_TOOLTIP'), HOTKEYS.UNDO)} `}>
              <RawButton dataLangId="TIMELINE_TOOLBAR_BTN_UNDO">
                <PrevIcon className={cx({ 'timeline-toolbar__history--disabled': !isUndoAvailable })} />
              </RawButton>
            </div>

            <div className="timeline-toolbar__history-item" onClick={onClickRedo} title={getTooltip(t('TIMELINE_TOOLBAR_BTN_REDO_TOOLTIP'), HOTKEYS.REDO)}>
              <RawButton dataLangId="TIMELINE_TOOLBAR_BTN_REDO">
                <NextIcon className={cx({ 'timeline-toolbar__history--disabled': !isRedoAvailable })} />
              </RawButton>
            </div>
          </div>
        </div>

        <If condition={__CFG__.TIMELINE.TIME_INPUT}>
          <div className="timeline-toolbar__duration">
            <InputRename
              ref={timeInputRef}
              className="project-rename"
              activeName={timeValue}
              maxVisibleLetter={12}
              letterWidth={10}
              selectOnFocus={false}
              onRename={handleChangeTime}
            />
          </div>
        </If>

        <div className="timeline-toolbar__buttons">
          <If condition={__CFG__.TIMELINE.DELETE}>
            <div className="timeline-toolbar__item timeline-toolbar__item--delete" onClick={deleteAssets} title={getTooltip(t('TIMELINE_TOOLBAR_BTN_DELETE_TOOLTIP'), HOTKEYS.DELETE)}>
              <RawButton disabled={selectedAssets.length === 0} dataLangId="TIMELINE_TOOLBAR_BTN_DELETE">
                <div className="icon deleteIcon" />
              </RawButton>
            </div>
          </If>
          <If condition={__CFG__.TIMELINE.CUT}>
            <div className="timeline-toolbar__item timeline-toolbar__item--cut" title={getTooltip(t('TIMELINE_TOOLBAR_BTN_CUT_TOOLTIP'), HOTKEYS.CUT_CLIPS)}>
              <RawButton onClick={split} disabled={cutButtonIsDisabled} dataLangId="IMELINE_TOOLBAR_BTN_CUT">
                <CutIcon />
              </RawButton>
            </div>
          </If>

          <div className="timeline-toolbar__item timeline-toolbar__item--copy" title={getTooltip(t('TIMELINE_TOOLBAR_BTN_COPY_TOOLTIP'), HOTKEYS.COPY)}>
            <RawButton onClick={copyAssets} disabled={!isSelectedAssets} dataLangId="TIMELINE_TOOLBAR_BTN_COPY">
              <CopyIcon />
            </RawButton>
          </div>

          <div className="timeline-toolbar__item timeline-toolbar__item--paste" title={getTooltip(t('TIMELINE_TOOLBAR_BTN_PASTE_TOOLTIP'), HOTKEYS.PASTE)}>
            <RawButton onClick={pasteAssets} disabled={!isCopiedAssets} dataLangId="TIMELINE_TOOLBAR_BTN_PASTE">
              <PasteIcon />
            </RawButton>
          </div>

          <div className="timeline-toolbar__item" title={t('TIMELINE_TOOLBAR_BTN_ADD_MARKER_TOOLTIP')}>
            <If condition={__CFG__.TIMELINE.ADD_MARKER}>
              <RawButton dataLangId="TIMELINE_TOOLBAR_BTN_ADD_MARKER">
                <CheckIcon />
              </RawButton>
            </If>
          </div>

          <div className="timeline-toolbar__item" title={t('TIMELINE_TOOLBAR_BTN_SETTINGS_TOOLTIP')}>
            <If condition={__CFG__.TIMELINE.SETTINGS}>
              <RawButton dataLangId="TIMELINE_TOOLBAR_BTN_SETTINGS" onClick={onSettingsClicked} disabled={!isSingleAssetSelected}>
                <SettingsIcon />
              </RawButton>
            </If>
          </div>

          <div className="timeline-toolbar__item timeline-toolbar__item--mediarecording">
            <If condition={__CFG__.TIMELINE.RECORD_AUDIO}>
              <MediaRecordingCombobox />
            </If>
          </div>
        </div>
      </div>
    </If>
  )
}
