import { getVideoMetaData } from 'helpers/getVideoMetaData'
import * as API from '~/ServerAPI'
import * as ActionTypes from '~/actions/ActionTypes'
import { setPlaybackClip } from './playback'
import { CHECK_STATUS_INTERVAL, checkStatus } from './sourceFiles'

// fast play if url exists
export const createMediaFile = (fileId, type, url) => async (dispatch, getState) => {
  try {
    const response = await API.getMediaFile(fileId, type)
    const item = response.data
    const videoParams = url
      ? { ...(await getVideoMetaData(url)), url }
      : {}
    const patchedItem = { ...item, ...videoParams }
    await dispatch({
      type: ActionTypes.SOURCE_FILE_CREATED,
      payload: { item: patchedItem },
    })
    await dispatch(setPlaybackClip(patchedItem))
    setTimeout(checkStatus, CHECK_STATUS_INTERVAL, { id: patchedItem.id, type }, dispatch, getState)
  } catch (e) {
    console.error(`error createMediaFile fileId:${fileId} ${e}`)
  }
}
