import { getRelativeAssetSize } from 'helpers/getRelativeAssetSize'
import { ImageAsset } from 'models/Asset'
import { getImageAspectRatio } from '~/Util/sourceFile'

export function createAssetFromSourceFile(file, { startTime,
  layerId,
  pregeneratedId,
  creationOrder,
  videoSize,
  aspectRatio }) {
  const fileData = {
    layerId,
    startTime,
    creationOrder,
  }

  if (file instanceof ImageAsset) {
    const { size, originalSize } = file.settings
    const { height, width } = size
    fileData.settings = { ...file.settings,
      relativeSize: getRelativeAssetSize({ width, height }, videoSize),
      aspectRatio: aspectRatio || getImageAspectRatio({ width, height }) }
    if (!originalSize) {
      fileData.settings.originalSize = fileData.settings.size
    }
  }
  return file.clone(fileData, { pregeneratedId })
}
