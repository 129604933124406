/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Scrollbars } from 'react-custom-scrollbars-2'
import './Scrollbars.scss'

class CustomScroll extends React.PureComponent {

  render() {
    const {
      // eslint-disable-next-line no-unused-vars
      trackSize, trackHorizontalSize, trackVerticalSize, tracksOverContent,
      // eslint-disable-next-line react/prop-types
      forwardedRef,
      className,
      renderViewClassName,
      renderTrackVerticalClassName,
      ...rest
    } = this.props
    return (
      <Scrollbars
        {...rest}
        hideTracksWhenNotNeeded
        className={cx('scroll-container', className)}
        renderView={props => this.renderView({ renderViewClassName, ...props })}
        renderTrackVertical={props => this.renderTrackVertical(
          { renderTrackVerticalClassName, ...props }
        )}
        renderThumbVertical={this.renderThumbVertical}
        renderTrackHorizontal={this.renderTrackHorizontal}
        renderThumbHorizontal={this.renderThumbHorizontal}
        ref={forwardedRef}
      />
    )
  }

  renderView = ({ style, renderViewClassName, ...props }) => {
    const { trackSize, forwardedRef } = this.props
    // const horizontalTrackSize = this.getHorizontalTrackSize()


    let horizontalThumbSize = 0
    if (forwardedRef && forwardedRef.current) {
      horizontalThumbSize = forwardedRef.current.getThumbHorizontalWidth()
    }

    return (
      <div
        {...props}
        className={cx('scroll-container__content', renderViewClassName)}
        // TODO:
        //  ideally, same should be done for vertical track too,
        //  but it's very complicated to implement because of negative margins (used to hide native scrollbars)
        style={(
          // (tracksOverContent || horizontalTrackSize === 0)
          !horizontalThumbSize
            ? style
            : { ...style, paddingBottom: trackSize }
        )}
      />
    )
  }

  renderTrackVertical = ({ renderTrackVerticalClassName, style, ...props }) => (
    <div
      {...props}
      style={{ ...style, width: this.getVerticalTrackSize() }}
      className={cx('scrollbar', 'scrollbar--vertical', renderTrackVerticalClassName)}
    />
  )

  renderTrackHorizontal = ({ style, ...props }) => (
    <div
      {...props}
      style={{ ...style, height: this.getHorizontalTrackSize() }}
      className="scrollbar scrollbar--horizontal"
    />
  )

  renderThumbVertical = ({ style, ...props }) => {
    const width = getThumbSize(this.getVerticalTrackSize())
    return (
      <div
        {...props}
        style={{ ...style, width, left: getThumbPosition(width) }}
        className="scroll-thumb scroll-thumb--vertical"
      />
    )
  }

  renderThumbHorizontal = ({ style, ...props }) => {
    const height = getThumbSize(this.getHorizontalTrackSize())
    return (
      <div
        {...props}
        style={{ ...style, height, top: getThumbPosition(height) }}
        className="scroll-thumb scroll-thumb--horizontal"
      />
    )
  }

  getVerticalTrackSize() {
    const { trackSize, trackVerticalSize } = this.props
    if (trackVerticalSize === 0) {
      return 0
    }
    return trackVerticalSize || trackSize
  }

  getHorizontalTrackSize() {
    const { trackSize, trackHorizontalSize } = this.props
    if (trackHorizontalSize === 0) {
      return 0
    }
    return trackHorizontalSize || trackSize
  }

}

// ---

function getThumbSize(barSize) {
  return barSize / 2
}

function getThumbPosition(thumbSize) {
  return thumbSize / 2
}

// ---

CustomScroll.propTypes = {
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  trackSize: PropTypes.number,
  trackVerticalSize: PropTypes.number,
  trackHorizontalSize: PropTypes.number,
  tracksOverContent: PropTypes.bool,
  children: PropTypes.node,
  renderViewClassName: PropTypes.string,
  renderTrackVerticalClassName: PropTypes.string,
}

CustomScroll.defaultProps = {
  forwardedRef: null,
  trackSize: 8,
  trackVerticalSize: null,
  trackHorizontalSize: null,
  children: null,
  tracksOverContent: false,
  renderViewClassName: '',
  renderTrackVerticalClassName: '',
}

export default React.forwardRef(
  /**
   *
   * @param {*} props
   * @param {*} ref
   * @returns
   */
  (props, ref) => (
    <CustomScroll {...props} forwardedRef={ref} />
  )
)
