import { insertLayer } from 'actions/insertLayer'
import { deleteLayer } from '~/actions/timeline'
import { HistoryActionType } from './HistoryActionType'
import { LayerHistoryAction } from './LayerHistoryAction'

export class LayerCreationAction extends LayerHistoryAction {

  constructor(...params) {
    super(HistoryActionType.LayerCreationAction, ...params)
  }

  * undo() {
    const { layer } = this
    yield deleteLayer(layer.id)
  }

  * redo() {
    const { index, layer } = this
    yield insertLayer(index, layer)
  }

}
