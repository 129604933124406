import { setEdition as setEditionAction } from 'actions/app'
import Button from 'components/base/Button'
import { CircularProgress } from 'components/base/CircularProgress/CircularProgress'
import { Dialog, DialogContent, DialogTitle } from 'components/base/Dialog/Dialog'
import { InputClearable } from 'components/base/InputClearable/InputClearable'
import { TranslationContext } from 'contexts/TranslationContext'
import { useAction } from 'hooks'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectInstallVersion } from 'selectors/app'
import { Modal } from 'types/modal'
import { ActivationError } from '../ActivationError'
import { ActiovationErrorModal } from '../activation-error-modal/ActivationErrorModal'
import { activateKey, addKey } from '../api'
import PROFILE_CONFIG from '../config'
import './styles.scss'

type props = {
  modal: Modal
}

export const AddLicenseModal = ({ modal }: props) => {
  const { LOCAL_STORAGE_ACTIVATION_KEY_DATE_VAR, LOCAL_STORAGE_EDITION_VAR, LOCAL_STORAGE_KEY_VAR } = PROFILE_CONFIG
  const setEdition = useAction(setEditionAction)
  const installVersion = useSelector(selectInstallVersion)
  const [ licenseKey, setLicenseKey ] = useState<string>('')
  const [ status, setStatus ] = useState<'loading' | 'success' | 'error' | undefined>()
  const [ error, setError ] = useState<ActivationError | undefined>(undefined)

  const handleChangeKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLicenseKey(e.target.value)
  }

  const { t } = useContext(TranslationContext)

  const handleClearKey = () => {
    setLicenseKey('')
  }

  const handleConfirm = async () => {
    if (licenseKey) {
      try {
        setStatus('loading')
        const { edition } = await activateKey(installVersion, licenseKey)
        setEdition(edition)
        localStorage.setItem(LOCAL_STORAGE_KEY_VAR, licenseKey)
        localStorage.setItem(LOCAL_STORAGE_ACTIVATION_KEY_DATE_VAR, new Date().toISOString())
        localStorage.setItem(LOCAL_STORAGE_EDITION_VAR, edition)
        addKey(licenseKey, edition)
        setStatus('success')
      } catch (e) {
        if (e instanceof ActivationError) {
          setStatus('error')
          setError(e)
        }
      }
    }
  }

  const renderModal = () => {
    switch (status) {
      case 'success':
        return (
          <>
            <DialogTitle className="license_title">
              {t('DESKTOP_ACTIVATION_FEATURE_TITLE_SUCCESSFULLY_ADDED_KEY')}
            </DialogTitle>
            <Button
              type="submit"
              className="license_ok-button"
              primary
              onClick={modal.close}
              autoFocus
            >
              {t('DESKTOP_ACTIVATION_FEATURE_BTN_GET_STARTED')}
            </Button>
          </>
        )
      case 'loading':
        return (
          <DialogContent className="license_progress">
            <CircularProgress
              size={100}
              endless
              percents={50}
              transparent
              text={undefined}
              progress={0}
            />
          </DialogContent>
        )
      default:
        return (
          <>
            <DialogTitle className="license_title">
              {t('DESKTOP_ACTIVATION_FEATURE_TITLE_ADD_NEW_LICENSE_KEY')}
            </DialogTitle>
            <DialogContent className="license_content">
              <InputClearable
                placeholder={t('DESKTOP_ACTIVATION_FEATURE_PLACEHOLDER_ENTER_LICENSE_KEY')}
                value={licenseKey}
                onChange={handleChangeKey}
                onClearInput={handleClearKey}
              />

              <Button
                disabled={!licenseKey}
                type="submit"
                className="license_ok-button"
                primary
                onClick={handleConfirm}
                autoFocus
                title={t('DESKTOP_ACTIVATION_FEATURE_BTN_CONFIRM_TOOLTIP')}
                data-lang-id="DESKTOP_ACTIVATION_FEATURE_BTN_CONFIRM"
              >
                {t('DESKTOP_ACTIVATION_FEATURE_BTN_CONFIRM')}
              </Button>
            </DialogContent>
          </>
        )
    }
  }

  return (
    <>
      {modal.active && (
      <Dialog
        open
        onClose={modal.cancel}
      >
        {renderModal()}
      </Dialog>
      )}
      <ActiovationErrorModal onClose={() => setError(undefined)} error={error} />
    </>
  )
}
