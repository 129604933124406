import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import * as UploadService from 'services/uploadService'
import { SOURCE_FILE_TYPES, FILE_TYPE } from '~/enums'
import * as Selectors from '~/selectors'
import * as API from '~/ServerAPI'
import { updateSourceFileStatus } from './sourceFiles'
import { openFolderById } from './folders'
import { createMediaFile } from './createMediaFile'

const CHUNK_SIZE = 1024 * 256

const uploadDropBoxFile = (id, file) => async () => {
  const { size, link } = file
  let start = 0

  /* eslint-disable no-await-in-loop */
  while (start < size) {
    const end = Math.min(file.size, start + CHUNK_SIZE)

    try {
      const chunk = await axios.get(link, {
        headers: {
          Range: `bytes=${start}-${end - 1}`,
          'Content-type': 'application/octet-stream',
        },
        responseType: 'arraybuffer',
      })

      try {
        await API.uploadMediaFile({
          id,
          chunk: chunk.data,
          start,
          end: end - 1,
          totalSize: size,
        })

        start = end
      } catch {
        // break 'while' if upload is failed, e.g. when item was removed from media
        break
      }
    } catch (e) {
      console.error('Failed to download file from dropbox', e)
      break
    }
  }
}

const uploadGoolgeDriveFile = (id, file) => async () => {
  const { token, id: googleFileId, size } = file
  let start = 0

  /* eslint-disable no-await-in-loop */
  while (start < size) {
    const end = Math.min(file.size, start + CHUNK_SIZE)

    try {
      const chunk = await axios.get(`https://www.googleapis.com/drive/v3/files/${googleFileId}`, {
        params: {
          alt: 'media',
          key: __CFG__.SOURCE_FILES_MANAGEMENT.GOOGLE_API_KEY,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          Range: `bytes=${start}-${end - 1}`,
        },
        responseType: 'arraybuffer',
      })

      try {
        await API.uploadMediaFile({
          id,
          chunk: chunk.data,
          start,
          end: end - 1,
          totalSize: size,
        })

        start = end
      } catch {
        // break 'while' if upload is failed, e.g. when item was removed from media
        break
      }
    } catch (e) {
      console.error('Failed to get file from google drive', e)
      break
    }
  }
}

const uploadMediaFile = (id, file) => dispatch => {
  const onChunkUpload = start => dispatch(updateSourceFileStatus({ id,
    currentFilePos: start,
    file }))

  const request = options => UploadService.uploadFileRequest({ ...options, type: 'media' })

  return UploadService.uploadFile({ id, file, onChunkUpload, request })
}

/**
 *
 * @param {File} file
 * @param {UploadService.FileType} type
 * @returns
 */
export const uploadFile = (file, type = 'media') => async (dispatch, getState) => {
  let response = null
  const folder = Selectors.selectImportFolderId(getState(), SOURCE_FILE_TYPES.MEDIA)
  await dispatch(openFolderById(SOURCE_FILE_TYPES.MEDIA, folder))
  try {
    response = await UploadService.createUploadSession(file.name,
      file.size, type, { folder })
  } catch (err) {
    // in case of attempt to load file larger than 2Gb, creation of upload
    // session will fail with code 507, so axios throws an exception
    response = err.response
  }

  if (response?.data.id !== undefined) {
    const { id } = response.data
    await dispatch(createMediaFile(id, FILE_TYPE.UPLOADED))
    if (!response.data.status) {
      if (file.dropbox) {
        dispatch(uploadDropBoxFile(id, file))
      } else if (file.token !== undefined) {
        dispatch(uploadGoolgeDriveFile(id, file))
      } else {
        dispatch(uploadMediaFile(id, file))
      }
    }
  }
}

// TODO: complete in the scope of #690 (retry upload) http://18.184.210.86/issues/690
export const retryUpload = id => async (dispatch, getState) => {
  const item = Selectors.getSourceFileById(getState(), id)
  if (item !== undefined) {
    dispatch(uploadMediaFile({ id, file: item.fileHandle, startPos: item.currentFilePos }))
  }
}
