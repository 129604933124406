import { insertLayer } from 'actions/insertLayer'
import { deleteLayer } from '~/actions/timeline'
import { HistoryActionType } from './HistoryActionType'
import { LayerHistoryAction } from './LayerHistoryAction'

export class LayerDeletionAction extends LayerHistoryAction {

  constructor(...params) {
    super(HistoryActionType.LayerDeletionAction, ...params)
  }

  * undo() {
    const { layer, index } = this
    yield insertLayer(index, layer)
  }

  * redo() {
    const { layer } = this
    yield deleteLayer(layer.id)
  }

}
