import { httpClient } from 'ServerAPI'
import * as ActionTypes from 'actions/ActionTypes'
import { DEFAULT_PROFILE_ID, DEFAULT_PROFILE_NAME } from 'constant'
import { DEFAULT_HOTKEYS } from 'enums'
import { mergeHotkeys } from 'general'
import produce from 'immer'
import { sdkService } from 'services/SDKService/SDKService'
import { isJSON } from '~/helpers/isJSON'
import PROFILE_HOTKEYS from '~/config/hotkeys'

const getDefaultHotkeyProfile = () => ({
  id: DEFAULT_PROFILE_ID,
  name: DEFAULT_PROFILE_NAME,
  readonly: true,
  hotkeys: PROFILE_HOTKEYS ?? DEFAULT_HOTKEYS,
})

const parseHotkeyProfiles = hotkeyProfiles => {
  const defaultProfile = getDefaultHotkeyProfile()
  const profiles = Object
    .entries(hotkeyProfiles?.profiles ?? {})
    .reduce((profiles, [ key, profile ]) => {
      if (key === DEFAULT_PROFILE_ID) return profiles

      const parsedProfile = {
        id: profile.id || crypto.randomUUID(),
        name: profile.name || key,
        readonly: profile.readonly || false,
        hotkeys: mergeHotkeys({
          customHotkeys: profile.hotkeys || profile,
          defaultHotkeys: defaultProfile.hotkeys,
        }),
      }

      return { ...profiles, [parsedProfile.id]: parsedProfile }
    }, { [defaultProfile.id]: defaultProfile })

  return {
    active: hotkeyProfiles?.active || DEFAULT_PROFILE_ID,
    profiles,
  }
}

const initialState = {
  fetched: false,
  displayName: '',
  avatarUrl: '',
  avatar: '',
  backUrl: null,
  data: null,
  customMediaTabContent: false,
  importsDefaultFolderId: null,
  recordsDefaultFolderId: null,
  customCSS: null,
  customJS: null,
  customLanguageUrl: null,
  customLanguage: null,
  companyName: null,
  appTitle: null,
  appDescription: null,
  favIcon: null,
  svgLogo: null,
  error: null,
  hotkeyProfiles: {
    active: DEFAULT_PROFILE_ID,
    profiles: {
      [DEFAULT_PROFILE_ID]: getDefaultHotkeyProfile(),
    },
  },
  searchHotkeyText: '',
}

const updateUserProfile = (state, action) => {
  const { displayName,
    avatarUrl,
    avatar,
    backUrl,
    data,
    customApiAuthToken,
    devProfile,
    hotkeysProfiles,
    recordsDefaultFolderId,
    importsDefaultFolderId,
    error } = action.payload
  state.displayName = displayName
  state.avatarUrl = avatarUrl
  state.avatar = avatar
  state.backUrl = backUrl
  state.recordsDefaultFolderId = recordsDefaultFolderId
  state.importsDefaultFolderId = importsDefaultFolderId
  state.fetched = true
  state.error = error
  if (hotkeysProfiles) {
    state.hotkeyProfiles = parseHotkeyProfiles(
      isJSON(hotkeysProfiles) ? JSON.parse(hotkeysProfiles) : hotkeysProfiles
    )
  }
  if (devProfile) {
    const { customCSS,
      customLanguageUrl,
      customLanguage,
      customJS,
      companyName,
      appTitle,
      appDescription,
      favIcon,
      svgLogo,
      defaultUserAvatar,
      hideDefaultUserAvatar,
      hideSVGLogo,
      callbackUrl,
      fetchFileUrl,
      mediaTabContentUrl,
      fetchThumbnailUrl,
      previewPlayerLogo,
      apiKey } = devProfile
    state.customLanguageUrl = customLanguageUrl
    state.customLanguage = customLanguage
    state.apiKey = apiKey
    state.defaultUserAvatar = defaultUserAvatar
    state.hideDefaultUserAvatar = hideDefaultUserAvatar
    state.hideSVGLogo = hideSVGLogo
    state.companyName = companyName
    state.appTitle = appTitle || __CFG__.TITLE
    state.customCSS = customCSS
    state.customJS = customJS
    state.appDescription = appDescription
    state.favIcon = favIcon
    state.svgLogo = svgLogo
    state.previewPlayerLogo = previewPlayerLogo
    if (callbackUrl) {
      sdkService.start({ callbackUrl, userData: data, customApiAuthToken })
    }
    if (mediaTabContentUrl) {
      state.customMediaTabContent = true
      httpClient.defaults.customApiUrls = {
        fetchFileUrl,
        mediaTabContentUrl,
        fetchThumbnailUrl,
      }
      httpClient.defaults.customApiAuthToken = customApiAuthToken
      httpClient.defaults.permanentFoldersIds = [ recordsDefaultFolderId, importsDefaultFolderId ]
    }
  }
}

const user = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.PATCH_USER_PROFILE:
      Object.entries(action.payload).forEach(([ key, value ]) => {
        state[key] = value
      })
      break
    case ActionTypes.UPDATE_USER_PROFILE:
      updateUserProfile(state, action)
      break
    case ActionTypes.UPDATE_USER_NAME:
      state.displayName = action.payload.name
      break
    case ActionTypes.SET_HOTKEY: {
      const { hotkey, combination } = action.payload
      state.hotkeyProfiles.profiles = {
        ...state.hotkeyProfiles.profiles,
        [state.hotkeyProfiles.active]: {
          ...state.hotkeyProfiles.profiles[state.hotkeyProfiles.active],
          hotkeys: {
            ...state.hotkeyProfiles.profiles[state.hotkeyProfiles.active].hotkeys,
            [hotkey]: combination,
          },
        },
      }
      break
    }
    case ActionTypes.ADD_HOTKEY_PROFILE: {
      const maxCustomNumber = Object.values(state.hotkeyProfiles.profiles)
        .map(p => Number(p.name.replace(/^\D+/g, ''))).sort((a, b) => b - a)[0]
      const name = `Custom preset ${maxCustomNumber + 1}`
      const profile = {
        id: crypto.randomUUID(),
        name,
        readonly: false,
        hotkeys: PROFILE_HOTKEYS ?? DEFAULT_HOTKEYS,
      }
      state.hotkeyProfiles.profiles = {
        ...state.hotkeyProfiles.profiles,
        [profile.id]: profile,
      }
      state.hotkeyProfiles.active = profile.id
      break
    }
    case ActionTypes.SEARCH_HOTKEY: {
      const { text } = action.payload
      state.searchHotkeyText = text
      break
    }
    case ActionTypes.DELETE_HOTKEY_PROFILE: {
      const { profile } = action.payload
      state.hotkeyProfiles.active = DEFAULT_PROFILE_ID
      delete state.hotkeyProfiles.profiles[profile]
      break
    }
    case ActionTypes.RESET_PROFILE_TO_DEFAULTS: {
      state.hotkeyProfiles.profiles[state.hotkeyProfiles.active] = getDefaultHotkeyProfile()
      break
    }
    case ActionTypes.RENAME_HOTKEY_PROFILE: {
      const { id } = action.payload
      const { active } = state.hotkeyProfiles
      state.hotkeyProfiles.profiles = {
        ...state.hotkeyProfiles.profiles,
        [id]: state.hotkeyProfiles.profiles[active],
      }
      delete state.hotkeyProfiles[active]
      state.hotkeyProfiles.active = id
      break
    }
    case ActionTypes.SET_ACTIVE_HOTKEY_PROFILE: {
      const { profile } = action.payload
      state.hotkeyProfiles.active = profile
      break
    }
  }
}

export default produce(user, initialState)
