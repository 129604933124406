import { TranslationContext } from 'contexts/TranslationContext'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { DEFAULT_FPS, RangeTools } from '~/Util'
import * as Actions from '~/actions'
import { ReactComponent as IconRendering } from '~/assets/video-player/ic_rendering.svg'
import { ReactComponent as IconUpload } from '~/assets/video-player/ic_upload.svg'
import { FILE_STATUS, PLAYBACK_STATE } from '~/enums'
import { useAction, useBind } from '~/hooks'
import BasePreviewPlayer from './BasePreviewPlayer'
import Preview from './Preview'
import { usePlayer } from './lib'
import usePlayerState from './lib/usePlayerState'
import useMediaPreviewState from './useMediaPreviewState'

// ---

function useFrameStepHandlers(fps, {
  seek,
  usePlayerCallback,
}) {
  const pause = useAction(Actions.playback.changeClipPlaybackState, PLAYBACK_STATE.PAUSE)

  const onFrameStep = usePlayerCallback(React.useCallback(
    (player, forward) => {
      const progress = player.getCurrentTime()
      const duration = player.getDuration()
      const frameDuration = 1 / fps
      const offset = forward ? frameDuration : -frameDuration
      const time = RangeTools.fitTo([ 0, duration ], progress + offset)

      pause()
      seek(time)
    },
    [ fps, pause, seek ]
  ))

  const onNextFrame = useBind(onFrameStep, true)
  const onPrevFrame = useBind(onFrameStep, false)

  return {
    onNextFrame,
    onPrevFrame,
  }
}

// ---

function MediaPreview(props) {
  const { srcVideo, fps, ...rest } = props
  const [ setPlayerRef, { usePlayerCallback, seek, getState }] = usePlayer()
  const [ , forceUpdate ] = React.useReducer(x => x + 1, 0)
  const { imageUrl, status, error } = props
  const pause = useAction(Actions.playback.changeClipPlaybackState,
    PLAYBACK_STATE.PAUSE)
  const onBufferingProgress = React.useCallback(forceUpdate, [ ])

  usePlayerState(getState)

  // ---

  const { onNextFrame, onPrevFrame } = useFrameStepHandlers(fps, {
    seek,
    usePlayerCallback,
  })

  const { t } = useContext(TranslationContext)

  const [
    { duration, progress },
    { onChangeDuration, onChangeProgress },
  ] = useMediaPreviewState()

  const uploading = error === undefined
    && (status === FILE_STATUS.UPLOAD
      || status === FILE_STATUS.IMPORT
      || status === FILE_STATUS.S3_UPLOAD
      || status === FILE_STATUS.CONVERT
      || (__APP_PROFILE__ === 'package' && status === FILE_STATUS.PREPARE_THUMBNAILS))

  const rendering = error === undefined
    && (status === FILE_STATUS.RENDER)

  return (
    <>
      <Choose>
        <When condition={uploading || rendering}>
          <div className="preview-placeholder-base">
            <div className="preview-placeholder-base__logo-importing">
              <If condition={uploading}>
                <IconUpload />
              </If>

              <If condition={rendering}>
                <IconRendering />
              </If>
            </div>

            <div className="preview-placeholder-base__text-importing">
              <If condition={uploading}>
                {t('MEDIA_PREVIEW_TEXT_IMPORTING')}
                ...
              </If>

              <If condition={rendering}>
                {t('MEDIA_PREVIEW_TEXT_RENDERING')}
                ...
              </If>
            </div>
          </div>
        </When>
        <Otherwise>
          <If condition={imageUrl}>
            <div className="media-image-preview">
              <img src={imageUrl} alt="preview" />
            </div>
          </If>
          <If condition={!imageUrl}>
            <Preview
              {...rest}

              duration={duration}
              progress={progress}

              onNextFrame={onNextFrame}
              onPrevFrame={onPrevFrame}

              onRewindTimeline={seek}
            >
              {playerProps => (
                /* note that this will work for audio content too */
                <BasePreviewPlayer
                  {...playerProps}
                  onReady={player => setPlayerRef(player)}
                  src={srcVideo}
                  onDuration={onChangeDuration}
                  onProgress={onChangeProgress}
                  onBufferingProgress={onBufferingProgress}
                  onEnded={pause}
                />
              )}
            </Preview>
          </If>
        </Otherwise>
      </Choose>
    </>
  )
}

MediaPreview.propTypes = {
  srcVideo: PropTypes.string.isRequired,
  fps: PropTypes.number,
  imageUrl: PropTypes.string,
  error: PropTypes.string,
  status: PropTypes.string,
}

MediaPreview.defaultProps = {
  fps: DEFAULT_FPS,
  imageUrl: undefined,
  status: undefined,
  error: undefined,
}

export default React.memo(MediaPreview)
