import React from 'react'

import PropTypes from 'prop-types'
import './HeadControlPanel.scss'
import cs from 'classnames'

export default function HeadControlPanel({
  caption,
  children,
}) {
  return (
    <div className="head">
      <div className={cs('head-control')}>
        <span className="head-control__caption">{caption}</span>
        <div className="head-control__wrap">
          {children}
        </div>
      </div>

    </div>
  )
}

HeadControlPanel.propType = {
  caption: PropTypes.string.isRequired,
  children: PropTypes.element,
}

HeadControlPanel.defaultProps = {
  children: null,
}
