import * as Actions from 'actions'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

import { DEFAULT_FPS } from '~/Util'
import { PLAYBACK_STATE } from '~/enums'
import * as Assets from '~/models/Asset'
import * as PT from '~/PropTypes'
import * as Selectors from '~/selectors'
import MediaPreview from './MediaPreview'
import TimelinePreview from './TimelinePreview'
import PlaybackKeyHandler from './PlaybackKeyHandler'

class PreviewContainer extends React.PureComponent {

  render() {
    const {
      clipPlaybackState, timelinePlaybackState,
      showVideoPlayer, url,
      isMediaItemPlaying,
      togglePlaybackPlaying,
      stopRecording,
      mediaRecordingInProgress,
      fps,
      imageUrl,
      status,
      error,
      isMedia,
      thumbnail,
    } = this.props

    const mediaPlaying = clipPlaybackState === PLAYBACK_STATE.PLAY && isMediaItemPlaying
    const playing = mediaPlaying || timelinePlaybackState === PLAYBACK_STATE.PLAY

    const handleStopRecording = playing
      ? debounce(stopRecording, 500, { leading: true, trailing: false })
      : () => {}

    const previewProps = {
      showVideoPlayer,
      showThumbnail: thumbnail,

      srcVideo: url,
      playing,
      fps,

      onPlayPause: mediaRecordingInProgress ? handleStopRecording : togglePlaybackPlaying,
      imageUrl,
      status,
      error,
    }

    return (
      <>
        <PlaybackKeyHandler />
        <Choose>
          <When condition={isMedia}>
            <MediaPreview
              {...previewProps}
            />
          </When>
          <Otherwise>
            <TimelinePreview
              {...previewProps}
            />
          </Otherwise>
        </Choose>
      </>
    )
  }

}

PreviewContainer.defaultProps = {
  url: '',
  fps: DEFAULT_FPS,

  clipPlaybackState: PLAYBACK_STATE.STOP,
  timelinePlaybackState: PLAYBACK_STATE.PAUSE,

  showVideoPlayer: false,
  isMediaItemPlaying: false,
  imageUrl: undefined,
  status: undefined,
  error: undefined,
  isMedia: false,
  thumbnail: false,
  mediaRecordingInProgress: false,
}

PreviewContainer.propTypes = {
  url: PropTypes.string,
  imageUrl: PropTypes.string,
  error: PropTypes.string,
  status: PropTypes.string,
  isMedia: PropTypes.bool,
  fps: PropTypes.number,

  showVideoPlayer: PropTypes.bool,
  isMediaItemPlaying: PropTypes.bool,

  clipPlaybackState: PT.ClipPlaybackState,
  timelinePlaybackState: PT.ClipPlaybackState,

  togglePlaybackPlaying: PropTypes.func.isRequired,

  thumbnail: PropTypes.bool,

  mediaRecordingInProgress: PropTypes.bool,
  stopRecording: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  const {
    clipPlaybackState,
    timelinePlaybackState,
    selectedClipId,
  } = state.playback

  const mediaRecordingInProgress = Selectors.recording.isRecordingStarted(state)
  const [ videoAsset ] = Selectors.getPlayingAssets(state, Assets.VideoAsset)
  const refVideo = Selectors.getReferenceVideoAsset(state)
  const mediaClip = Selectors.getSourceFileById(state, selectedClipId)
  const props = {
    isMediaItemPlaying: mediaClip?.canPlay,
    clipPlaybackState,
    timelinePlaybackState,
    mediaRecordingInProgress,
  }

  if (mediaClip !== undefined) {
    const { url, status, error, fps } = mediaClip
    const clipParams = { status, error, isMedia: true }

    if (mediaClip instanceof Assets.ImageAsset) {
      return { ...clipParams, imageUrl: url }
    }

    const isAudioAsset = mediaClip instanceof Assets.AudioAsset
    return {
      ...clipParams,
      ...props,
      url,
      fps,
      thumbnail: isAudioAsset,
      showVideoPlayer: true,
    }
  }

  return {
    ...props,
    url: videoAsset?.url,
    fps: (refVideo?.fpsNum / refVideo?.fpsDenum) || DEFAULT_FPS,
    showVideoPlayer: Boolean(videoAsset)
    || Boolean(Selectors.getPlayingAssets(state, Assets.TextAsset).length > 0),
  }
}

const mapDispatchToProps = dispatch => ({
  togglePlaybackPlaying: () => dispatch(Actions.playback.togglePlaybackPlaying()),
  stopRecording: () => dispatch(Actions.recording.stopRecording()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PreviewContainer)
