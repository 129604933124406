import Divider from 'components/base/Divider/Divider'
import React from 'react'
import { ActivationBannerModal } from '../activation-banner-modal/activation-banner-modal'
import { ActivationBarComponent } from '../activation-bar-component/activation-bar-component'
import { ActiovationErrorModal } from '../activation-error-modal/ActivationErrorModal'
import { ActivationVariantsComponent } from '../activation-variants-component/activation-variants-component'
import PROFILE_CONFIG from '../config'
import { useActivationSystem } from '../use-activation-system'
import './styles.scss'

export const ActivationComponent = () => {
  const {
    keys,
    openRegPage,
    url,
    activateKey,
    activeKey,
    userEmail,
    error,
    skipError,
    isLoading,
  } = useActivationSystem()
  return (
    <>
      {!activeKey || isLoading ? (
        <div className="activation-component">
          <ActivationBarComponent />
          <Divider className="activation-divider" orientation="vertical" />
          <ActivationVariantsComponent
            isLoading={isLoading}
            userEmail={userEmail}
            onSelectKey={activateKey}
            keys={keys}
            url={url}
            onBrowserOpen={openRegPage}
          />
        </div>
      ) : null}
      <ActiovationErrorModal onClose={skipError} error={error} />
      {activeKey === PROFILE_CONFIG.FREE_KEY && <ActivationBannerModal />}
    </>
  )
}
