import { DEFAULT_HOTKEYS, HOTKEYS, EMPTY_HOTKEY_COMBINATION, EXCLUDE_HOTKEY } from 'enums'

// Prepares a set of default hotkeys with empty values and excludes some
export const switchUsedHotkeys = ({
  switchOffKeys = [],
  excludedKeys = [],
}: {
  switchOffKeys: Array<HotkeysType>,
  excludedKeys?: Array<HotkeysType>
}) => (
    Object.keys(DEFAULT_HOTKEYS) as Array<HotkeysType>).reduce((result, key) => {
  // eslint-disable-next-line no-param-reassign
  result[key] = !switchOffKeys.includes(key)
    ? DEFAULT_HOTKEYS[key as typeof HOTKEYS[HotkeysType]]
    : EMPTY_HOTKEY_COMBINATION
  if (excludedKeys.includes(key)) {
    // eslint-disable-next-line no-param-reassign
    result[key] = EXCLUDE_HOTKEY
  }
  return result
}, {} as Record<HotkeysType, string>)

export const mergeHotkeys = ({
  customHotkeys,
  defaultHotkeys,
}: {
  customHotkeys: Record<HotkeysValueType, string>
  defaultHotkeys: Record<HotkeysValueType, string>
}) => Object.entries(defaultHotkeys).reduce((result, [ key, defaultCombination ]) => {
  if (!Object.hasOwn(customHotkeys, key)) {
    const hasConflict = Object
      .values(customHotkeys)
      .find(customCombination => customCombination === defaultCombination)
    // eslint-disable-next-line no-param-reassign
    result[key as HotkeysValueType] = hasConflict ? EMPTY_HOTKEY_COMBINATION : defaultCombination
  } else {
    // eslint-disable-next-line no-param-reassign
    result[key as HotkeysValueType] = customHotkeys[key as HotkeysValueType]
  }
  return result
}, { ...customHotkeys } as Record<HotkeysValueType, string>)
