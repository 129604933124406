import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { NWShortCutContext } from 'contexts/NWShortCutContext'
import { useKeyCodeListener, useResizeSensor } from 'hooks'

/*
 * Hook for <Preview /> Component
 */
type UsePreview = {
    onToggleFullscreen(): void,
    isFullScreen(): void,
    onResize(): void,
    onCloseFullscreen(): void,
}

const FULL_SCREEN_EVENTS = [
  'fullscreenchange',
  'mozfullscreenchange',
  'MSFullscreenChange',
  'webkitfullscreenchange',
]

export const usePreview = ({ onToggleFullscreen, isFullScreen, onResize, onCloseFullscreen }: UsePreview) => {
  const refPlayerBox = React.useRef<null | HTMLDivElement>(null)
  const playerRef = useRef<null | HTMLVideoElement>(null)

  const { registerNWGlobalHotKey, unRegisterNWGlobalHotKey } = useContext(NWShortCutContext)

  useEffect(() => {
    function fullscreenChange() {
      onToggleFullscreen()
    }

    FULL_SCREEN_EVENTS.forEach(screenEvent => {
      document.addEventListener(screenEvent, fullscreenChange, false)
    })

    return () => {
      FULL_SCREEN_EVENTS.forEach(screenEvent => {
        document.removeEventListener(screenEvent, fullscreenChange, false)
      })
    }
  }, [ onToggleFullscreen ])

  useEffect(() => {
    if (!isFullScreen) {
      unRegisterNWGlobalHotKey()
    }
  }, [ isFullScreen, unRegisterNWGlobalHotKey ])

  useKeyCodeListener(React.useRef(document), 'Escape', () => {
    onCloseFullscreen()
    unRegisterNWGlobalHotKey()
  })
  useResizeSensor(refPlayerBox, onResize)

  const handleFullscreen = useCallback(() => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
      unRegisterNWGlobalHotKey()
    } else if (playerRef.current) {
      playerRef.current.requestFullscreen()
      registerNWGlobalHotKey()
    }
  }, [ registerNWGlobalHotKey, unRegisterNWGlobalHotKey ])

  return { handleFullscreen, playerRef, refPlayerBox }
}
