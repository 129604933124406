import { all, select, takeEvery } from 'redux-saga/effects'

import { patchUser } from 'ServerAPI'
import { hotkeyProfilesExcludeDefaultSelector } from 'selectors/user-profile-selector'
import * as ActionTypes from '~/actions/ActionTypes'

function* handleUpdateHotkeyProfile() {
  const hotkeysProfiles = yield select(hotkeyProfilesExcludeDefaultSelector)
  yield patchUser({ hotkeysProfiles: JSON.stringify(hotkeysProfiles) })
}

function* watchAll() {
  yield all([ takeEvery([
    ActionTypes.SET_HOTKEY,
    ActionTypes.SET_ACTIVE_HOTKEY_PROFILE,
    ActionTypes.ADD_HOTKEY_PROFILE,
    ActionTypes.DELETE_HOTKEY_PROFILE,
    ActionTypes.RENAME_HOTKEY_PROFILE,
    ActionTypes.RESET_PROFILE_TO_DEFAULTS,
  ], handleUpdateHotkeyProfile),
  ])
}

export default watchAll
