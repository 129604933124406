import { secondsToTimelineTime } from '~/Util'

export const getVideoMetaData = url => new Promise(res => {
  const video = document.createElement('video')
  video.preload = 'metadata'

  video.onloadedmetadata = () => {
    window.URL.revokeObjectURL(video.src)
    const { duration, videoWidth, videoHeight } = video
    res({ duration: secondsToTimelineTime(duration), width: videoWidth, height: videoHeight })
  }

  video.src = url
})
