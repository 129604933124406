import { NW_JS_APP_PROFILES } from 'enums'

export const getLang = () => {
  // special case for pt-BR
  let navLang = navigator.language || navigator.userLanguage
  if (navLang === 'pt-BR') {
    navLang = 'bz'
  }
  let lang = (navLang).substr(0, 2)
  if (NW_JS_APP_PROFILES.includes(__APP_PROFILE__)) {
    lang = (global.process.env.LANG || 'en-US').substr(0, 2)
  }
  return lang
}
