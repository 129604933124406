import * as Actions from 'actions'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'
import { connect, useSelector } from 'react-redux'
import { TRANSITIONS } from '~/enums'
import { ImageAsset, TextAsset, VideoAsset, MediaRecordingAsset, AudioAsset, TransitionAsset } from '~/models/Asset'
import * as PT from '~/PropTypes'
import * as Selectors from '~/selectors'
import { getCachedThumbnail } from '~/ServerAPI'
import { getWindowWidth, isCtrlKeyPressed, normalizeThumbnail, time2Pixel } from '~/Util'
import { isResizableAsset } from '~/Util/assets'
import { getAssetMediaThumbnailFromBackend } from '~/helpers/assets/getAssetMediaThumbnailFromBackend'
import { Context as TimelineGeometryContext } from '../GeometryContextProvider'
import { TimelineScrollPositionContext } from '../ScrollPositionContext'
import AssetDropTarget from './AssetDropTarget'
import DragTransitionContainer from './DragTransitionContainer'
import { Expander } from './Expander'
import './LayerItem.scss'
import { TRANSITION_MIN_WIDTH } from './TransitionAsset'
import { isVoiceover } from '~/Util/recording'
import { MIN_TIMELINE_ITEM_DURATION_PX } from '~/constant'

import styles from './layerItem.module.scss'

const MUTED_AUDIO_TRACK_COLOR = '#43745f'
const REGULAR_AUDIO_TRACK_COLOR = '#50ffc0'

function getAudioTrackStyle(enabled = true) {
  const color = enabled ? REGULAR_AUDIO_TRACK_COLOR : MUTED_AUDIO_TRACK_COLOR
  return `linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0), 50px, ${color}, 50px, ${color}),`
}

function getAudioThumbnail(asset) {
  return isVoiceover(asset) ? styles.voiceItem : styles.audioItem
}

function getAudioBorderStyle(asset) {
  return isVoiceover(asset) ? styles.voiceItemBorder : styles.audioItemBorder
}

// TODO: rework component to divide logic of different asset types by separate components
class LayerItem extends React.PureComponent {

  constructor(props) {
    super(props)
    const { connectDragSource } = this.props

    // @link https://docs.google.com/document/d/1vsdg-XX2805oFctMGgXkvJPQAes0kQJLksRKMBejKHg/edit?ts=5e997f22#bookmark=id.p2oawjx3ukgl
    this.thumbWidth = 103
    this.thumbHeight = 58

    this.width = 0

    this.scrollTimer = null

    this.mouseDownXRef = React.createRef(null)

    this.thumbnailsNumber = 0
    this.itemRef = React.createRef()

    if (connectDragSource) {
      connectDragSource(this.itemRef)
    }

    // timeout before getting thumbnails when scale is changed,
    // because dragging of scale slider will produce huge amount
    // of thumbnails requests
    this.onScaleChange = debounce(() => {
      const { timelineScrollPosition } = this.props
      this.initThumbnailsTimeStamp()
      this.loadCachedThumbnails(timelineScrollPosition)
    }, 200)
  }

  state = {
    thumbnailsLoaded: false,
    thumbnails: {}, // format { [start index]: [array of thumbnails] }
    hover: false,
    isResizing: false,
  }

  render() {
    const { asset, isDragging, transitionLeft, transitionRight,
      onClick, asDragPreview, isMovingSlider, selectedAssetsIds, onTranslate } = this.props
    const rootStyle = this.getRootStyles()
    const { width, borderLeftWidth } = rootStyle
    const { isResizing } = this.state

    return (
      <AssetDropTarget
        asset={asset}
        isDragging={isDragging}
        ref={this.connectAssetDropTarget}
        style={rootStyle}
        onClick={this.onClick}
        onMouseDown={this.onMouseDown}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        borderLeftWidth={borderLeftWidth}
        selectedAssetsIds={selectedAssetsIds}
      >
        <If condition={this.isResizable()
          && !asDragPreview && !(asset instanceof MediaRecordingAsset)
          && (!isMovingSlider || asset.selected)}
        >
          <Expander
            onStartExpanding={this.startResizing}
            onEndExpanding={this.endResizing}
            asset={asset}
            isDragging={isResizing}
            left={transitionLeft}
            right={transitionRight}
          />
        </If>
        <If condition={asset instanceof ImageAsset && !asset.mediaDeleted}>
          <div className="layer-item--image__text" style={{ marginLeft: `${this.thumbWidth + 4}px` }}>{asset.name}</div>
        </If>
        <If condition={asset.mediaDeleted}>
          <div className="layer-item--deleted">
            {/* this width / length of one deleted message item */}
            {new Array(Math.max(1, Math.ceil(this.width / 104))).fill(0).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`deletedMedia-${asset.id}-${index}`} className="layer-item--deleted__elem">
                <div className="layer-item--deleted__elem__icon">!</div>
                <div className="layer-item--deleted__elem__text">{onTranslate?.(asset.errorType) ?? asset.errorMessage}</div>
              </div>
            ))}
          </div>
        </If>
        <If condition={(asset instanceof AudioAsset || asset instanceof MediaRecordingAsset)
            && !asset.mediaDeleted}
        >
          <div className={cx(styles.audioMainContainer, getAudioBorderStyle(asset))}>
            <div className={cx(styles.audioContainer, getAudioThumbnail(asset))} />
            <div className={styles.audioTextContainer}>
              <div className={cx(styles.audioIconBox,
                { [styles.voiceIcon]: isVoiceover(asset),
                  [styles.audioIcon]: !isVoiceover(asset) })}
              />
              <div className={styles.audioName}>{asset.name}</div>
            </div>
          </div>
        </If>
        <If condition={asset instanceof TextAsset}>
          <div className="layer-item--text__text">{asset.text}</div>
          <div className="layer-item--text__description">{asset.description}</div>
        </If>
        <If condition={asset.canHaveTransition}>
          <If condition={
            transitionLeft !== null
            && transitionLeft.type === TRANSITIONS.FADEIN
          }
          >
            <DragTransitionContainer
              key={transitionLeft.id}
              onClick={onClick}
              asset={transitionLeft}
              assetWidth={width}
              borderLeftWidth={borderLeftWidth}
            />
          </If>
          <If condition={transitionRight !== null}>
            <DragTransitionContainer
              key={transitionRight.id}
              onClick={onClick}
              asset={transitionRight}
              assetWidth={width}
              borderLeftWidth={borderLeftWidth}
            />
          </If>
        </If>
      </AssetDropTarget>
    )
  }

  async componentDidMount() {
    const { asset, onMount, scale } = this.props
    if (asset?.duration) {
      const width = time2Pixel(asset.duration, scale)
      this.width = width
    }
    if (this.shouldLoadThumbnails) {
      const { timelineScrollPosition } = this.props
      this.initThumbnailsTimeStamp()
      await this.loadCachedThumbnails(timelineScrollPosition)
    } else if (this.shouldLoadAssetMediaThumbnail) {
      this.loadAssetMediaThumbnail()
    }
    onMount(asset.id)
  }

  componentDidUpdate(prevProps) {
    const { scale, timelineScrollPosition, asset } = this.props

    if (this.shouldLoadThumbnails) {
      const { progress } = asset
      if (prevProps.asset.progress !== progress) {
        this.loadCachedThumbnails(timelineScrollPosition, progress)
      }

      if (prevProps.scale !== scale) {
        this.initThumbnailsTimeStamp()
        this.onScaleChange()
      }

      if (prevProps.timelineScrollPosition !== timelineScrollPosition) {
        if (this.scrollTimer) {
          clearTimeout(this.scrollTimer)
        }
        this.scrollTimer = setTimeout(() => {
          this.loadCachedThumbnails(timelineScrollPosition)
        }, 500)
      }
    }
  }

  onClick = e => {
    // don't send event to the parent (Layer)
    e.stopPropagation()
    e.persist()
    // protection from asset resizing click
    if (this.mouseDownXRef.current === e.pageX) {
      const { asset, onClick } = this.props
      onClick(asset.id, {
        ctrlKey: isCtrlKeyPressed(e),
        shiftKey: e.shiftKey,
      })
    }
  }

  onMouseDown = e => {
    e.persist()
    this.mouseDownXRef.current = e.pageX
    const { toggleSelect, selectedAssetsIds, asset } = this.props
    if (!selectedAssetsIds.includes(asset.id)) {
      if (!isCtrlKeyPressed(e) && !e.shiftKey) {
        toggleSelect(asset.id)
      }
    }
  }

  onMouseEnter = () => {
    this.setState({ hover: true })
  }

  onMouseLeave = () => {
    this.setState({ hover: false })
  }

  isResizable() {
    const { asset, scale, transitionLeft, transitionRight } = this.props
    const { isResizing } = this.state

    const trlWidth = time2Pixel(transitionLeft?.duration || 0, scale)
    const trrWidth = time2Pixel(transitionRight?.duration || 0, scale)
    const width = time2Pixel(asset.duration, scale)
    if (isResizing) return true

    return Math.round((width - trlWidth - trrWidth) * 100) / 100 >= MIN_TIMELINE_ITEM_DURATION_PX
      && isResizableAsset(asset)
  }

  getTransitionGeometryOffset() {
    const { scale, transitionRight, transitionLeft } = this.props
    const geometry = { left: 0, width: 0 }
    if (transitionRight) {
      const { type, duration } = transitionRight
      const width = time2Pixel(duration, scale)
      switch (type) {
        case TRANSITIONS.DISSOLVE:
        case TRANSITIONS.FADEOUT:
          geometry.width = Math.max(TRANSITION_MIN_WIDTH, width)
          break
        default: break
      }
    }

    if (transitionLeft) {
      const { type, duration } = transitionLeft
      const width = time2Pixel(duration, scale)
      switch (type) {
        case TRANSITIONS.DISSOLVE:
        case TRANSITIONS.FADEIN:
          geometry.left = Math.max(TRANSITION_MIN_WIDTH, width)
          geometry.width += geometry.left
          break
        default: break
      }
    }
    // el.style.backgroundPositionX = `${-transitionWidth}px, 80px, 185px`
    // el.style.backgroundPositionX = `${0}px, 105px, 210px`
    return geometry
  }

  get shouldLoadThumbnails() {
    const { asset, asDragPreview } = this.props
    return !asDragPreview && (asset instanceof VideoAsset)
  }

  get shouldLoadAssetMediaThumbnail() {
    const { asset } = this.props
    return ((asset instanceof VideoAsset
        || asset instanceof AudioAsset
        || asset instanceof ImageAsset))
  }

  getRootStyles() {
    const { asset, scale, style } = this.props
    const transitionGeometryOffset = this.getTransitionGeometryOffset()
    const left = time2Pixel(asset.startTime, scale) + transitionGeometryOffset.left
    let width = time2Pixel(asset.duration, scale) - transitionGeometryOffset.width

    if (asset instanceof TransitionAsset) {
      width = Math.max(width, TRANSITION_MIN_WIDTH)
    }

    if (width < 0) {
      width = 0
    }

    const borderTopWidth = width > 0 ? 1 : 0
    const borderLeftWidth = width > 0 ? 1 : 0
    const borderRightWidth = borderLeftWidth
    const borderBottomWidth = borderTopWidth

    const borderWidths = {
      borderTopWidth,
      borderLeftWidth,
      borderRightWidth,
      borderBottomWidth,
    }

    const styles = {
      left,
      width,
      ...borderWidths,
    }

    let backgroundStyles
    const { thumbnailsLoaded } = this.state
    if (!thumbnailsLoaded) {
      if (asset.thumbnail && !asset.mediaDeleted) {
        if (asset instanceof AudioAsset) {
          backgroundStyles = {}
        } else {
          backgroundStyles = {
            backgroundImage: `${this.audioTrackStyle}url(${normalizeThumbnail(asset.thumbnail)})`,
            backgroundSize: `auto ${this.thumbHeight}px`,
            backgroundRepeat: asset instanceof ImageAsset ? 'no-repeat' : 'repeat',
            backgroundPositionX: 'left',
            backgroundOrigin: 'border-box',
          }
        }
      }
    } else {
      const { thumbnails } = this.state
      const startIndexes = Object.keys(thumbnails)
      const backgrounds = startIndexes
        .reduce((acc, startIndex, index) => {
          let backgroundsSeries = thumbnails[startIndex]
          if (index === startIndexes.length - 1 && asset.thumbnail) {
            backgroundsSeries = [ ...backgroundsSeries, asset.thumbnail ]
          }
          backgroundsSeries = backgroundsSeries.map((base64, i, a) => `url(${normalizeThumbnail(base64)}) ${i < a.length - 1 || index < startIndexes.length - 1 ? 'no-repeat' : 'repeat'} border-box ${this.thumbWidth * (i - 1) + this.thumbWidth * startIndex}px`)
          return [ ...acc, ...backgroundsSeries ]
        }, [])
      backgroundStyles = {
        background: `${this.audioTrackStyle} ${backgrounds.join(',')}`,
      }
    }

    return {
      ...styles,
      ...backgroundStyles,
      ...style,
    }
  }

  get audioTrackStyle() {
    const { asset, muted } = this.props
    const { hover } = this.state
    if (asset.hasAudio && (!hover || asset.selected)) {
      return getAudioTrackStyle(!muted)
    }
    return ''
  }

  // TODO: move loadCachedThumbnails to Actions, LayerItem
  // will just display loaded thumbnails
  async loadCachedThumbnails(scrollLeft = 0, progress = 100) {
    const self = this

    const { asset, scale, updateAssetThumbnail } = this.props

    if (asset.fileId === null || asset.mediaDeleted) {
      return
    }

    const totalWidth = Math.min(
      time2Pixel(asset.duration, scale),
      scrollLeft + getWindowWidth()
    )

    const width = Math.min(
      time2Pixel(asset.duration, scale),
      getWindowWidth()
    )

    // eslint-disable-next-line no-shadow
    function getThumbnailsNumber(width) {
      return Math.round(Math.ceil(width / self.thumbWidth) * progress / 100)
    }

    const thumbnailsNumber = getThumbnailsNumber(width)
    const totalThumbnailsNumber = getThumbnailsNumber(totalWidth)

    const startIndex = totalThumbnailsNumber - thumbnailsNumber

    const prevThumbnailsNumberValue = this.thumbnailsNumber
    this.thumbnailsNumber = thumbnailsNumber

    const requests = []

    // getting cached thumbnails in parallel, it is probably faster
    for (let i = startIndex; i < totalThumbnailsNumber; ++i) {
      requests.push(
        getCachedThumbnail(
          asset.fileId,
          asset.type,
          Math.round(this.thumbnailsTimeStep * i + asset.mediaStart)
        ).catch(err => {
          console.error(err)
        })
      )
    }

    try {
      const thumbnails = (await Promise.all(requests))
        // TODO: check why response is undefined sometimes (usually, when a LOT of requests are sent simultaneously)
        .map(response => response?.data?.data)
        .filter(x => x !== undefined)

      if (thumbnails.length > 0) {
        // eslint-disable-next-line react/destructuring-assignment
        this.thumbnailsNumber = this.state.thumbnails.length + thumbnails.length
        this.setState(state => ({
          thumbnailsLoaded: true,
          // eslint-disable-next-line react/destructuring-assignment
          thumbnails: { ...state.thumbnails, [startIndex]: thumbnails },
        }))

        const { id, thumbnail } = asset

        // NOTE: set thumbnail for splitted part of video
        if (thumbnail !== thumbnails[0] && startIndex === 0) {
          const newThumbnail = thumbnails[0]
          updateAssetThumbnail(id, newThumbnail)
        }
      } else {
        this.thumbnailsNumber = prevThumbnailsNumberValue
      }
    } catch (e) {
      console.error(e)
    }
  }

  async loadAssetMediaThumbnail() {
    const { asset, updateAssetThumbnail } = this.props
    if (!asset.thumbnail) {
      const thumbnail = await getAssetMediaThumbnailFromBackend(asset)
      if (thumbnail) {
        updateAssetThumbnail(asset.id, thumbnail)
      }
    }
  }

  startResizing = () => {
    const { onDisableDrag } = this.props
    this.setState({ isResizing: true })
    onDisableDrag()
  }

  endResizing = () => {
    const { onEnableDrag } = this.props
    this.setState({ isResizing: false })
    onEnableDrag()
  }

  // TODO: move loadCachedThumbnails to Actions, LayerItem

  connectAssetDropTarget = ref => {
    const { connectDragSource } = this.props
    this.itemRef = ref
    if (connectDragSource) {
      connectDragSource(this.itemRef)
    }
  }

  initThumbnailsTimeStamp = () => {
    const { asset, scale } = this.props
    if (asset?.duration) {
      const width = time2Pixel(asset.duration, scale)
      this.width = width
    }
    const thumbnailsNumber = Math.ceil(this.width / this.thumbWidth)
    this.thumbnailsTimeStep = asset.duration / thumbnailsNumber
    this.thumbnailsNumber = 0
    this.setState({
      thumbnailsLoaded: false,
      thumbnails: {},
    })
  }

}

LayerItem.defaultProps = {
  onClick: () => { },
  onDisableDrag: () => { },
  onEnableDrag: () => { },
  onMount: () => { },
  connectDragSource: undefined,
  asDragPreview: false,
  timelineScrollPosition: 0,
  isDragging: false,
  transitionRight: null,
  transitionLeft: null,
  muted: false,
  updateAssetThumbnail: () => {},
  isMovingSlider: false,
  selectedAssetsIds: [],
  toggleSelect: () => {},
  onTranslate: () => {},
}

LayerItem.propTypes = {
  connectDragSource: PropTypes.func,
  onClick: PropTypes.func,
  onDisableDrag: PropTypes.func,
  onEnableDrag: PropTypes.func,
  onMount: PropTypes.func,
  asset: PT.LayerAsset.isRequired,
  scale: PropTypes.number.isRequired,
  transitionRight: PT.Asset,
  transitionLeft: PT.Asset,
  asDragPreview: PropTypes.bool,
  isDragging: PropTypes.bool,
  timelineScrollPosition: PropTypes.number,
  muted: PropTypes.bool,
  updateAssetThumbnail: PropTypes.func,
  isMovingSlider: PropTypes.bool,
  toggleSelect: PropTypes.func,
  selectedAssetsIds: PropTypes.arrayOf(PropTypes.string),
  onTranslate: PropTypes.func,
}

const mapStateToProps = state => ({
  scale: state.timeline.scale,
  transitionAssets: Selectors.getTransitionAssets(state),
})

const mapDispatchToProps = dispatch => ({
  onClick: (id, modifiers) => {
    dispatch(Actions.layer.selectAsset(id, modifiers))
  },
  updateAssetThumbnail: (assetId, thumbnail) => dispatch(
    Actions.layer.updateAssetInPreview(assetId, { thumbnail })
  ),
  toggleSelect: assetId => dispatch(Actions.layer.toggleAssetsSelected([ assetId ])),
})

function LayerItemContainer(props) {
  const assetTransitions = useSelector(state => (
    Selectors.getTransitionsByAssetId(state, props.asset.id, { named: true })
  ))
  const { scrollLeft } = React.useContext(TimelineScrollPositionContext)
  const { timelineWidth } = React.useContext(TimelineGeometryContext)
  const muted = useSelector(
    state => props.asDragPreview
      ? undefined
      : Selectors.getLayerById(state, props.asset.layerId).muted
  )
  const isMovingSlider = useSelector(Selectors.getIsMovingSlider)

  return (
    <LayerItem
      {...props}
      timelineScrollPosition={scrollLeft}
      transitionLeft={assetTransitions.left}
      transitionRight={assetTransitions.right}

      // This prop isn't used by component directly,
      // but serves as cache-buster, to re-render item when timeline is resized,
      // to update item geometry respectively
      timelineWidth={timelineWidth}
      muted={muted}
      isMovingSlider={isMovingSlider}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LayerItemContainer)
