import React, { ReactNode } from 'react'

export type ContextStateType = { projectId: string, visible: boolean, top: number, left: number }
export type IContextProps = { children: ReactNode }
export type ProjectMenuContextProps = {
    contextMenu: ContextStateType,
    handleContextMenu(e: MouseEvent | KeyboardEvent | null, data?: ContextStateType): void,
}

export const ProjectMenuContext = React.createContext<ProjectMenuContextProps>({} as ProjectMenuContextProps)
