/* eslint-disable no-console */
import * as ActionTypes from 'actions/ActionTypes'
import { createMediaFile } from 'actions/createMediaFile'
import * as xmlFormat from 'xml-formatter'
import { createEditingTask } from '~/ServerAPI'
import { createEditingTaskShotStack } from '~/ShotStackServerAPI'
import { getVideoPosition } from '~/Util/assets'
import { FILE_TYPE, RENDER_BACKEND, TASK_STATUS } from '~/enums'
import * as Selectors from '~/selectors'
import { selectActiveProjectId, selectProjectForSaving } from '~/selectors/projectData'
import { checkStatusLongPolling } from './checkStatusLongPolling'
import createEditingXTL from './lib'
import { createKonvaShape } from './lib/createKonvaShape'
import { createShotStackTimeline } from './lib/createShotStackTimeline'
import { uploadOverlays } from './overlayAssets'

// ---

export const addEditingTask = ({ task, loading = false }) => ({
  type: ActionTypes.ADD_EDITING_TASK,
  payload: { task },
  meta: { loading },
})

export const addEditingTaskError = ({ err }) => ({
  type: ActionTypes.ADD_EDITING_TASK,
  payload: { err },
  error: true,
})

export const setSelectedTask = id => ({
  type: ActionTypes.SET_SELECTED_TASK,
  payload: { id },
})

export const setReference = reference => ({
  type: ActionTypes.SET_REFERENCE,
  payload: reference,
})

export const prepareTask = (name, path = '') => ({
  type: ActionTypes.PREPARE_EDITING_TASK,
  payload: { name, path },
})

export const requestCreateTask = (outputName, format) => async (dispatch, getState) => {
  dispatch(addEditingTask({ loading: true }))

  try {
    const state = getState()
    const activeProjectId = selectActiveProjectId(state)
    const previewParams = Selectors.selectPreviewParams(state)
    const visibleLayerIds = Selectors.getVisibleLayerIds(state)
    const refVideo = Selectors.getReferenceVideoAsset(state)
    const { scale, boundingRect } = previewParams

    let preparedImages = [] // { assetId, id, videoPosition }

    const assetBlobs = [] // { assetId, blob }
    const overlayAssets = Selectors.selectExistingOverlayAssets(state)
    const imageCreationPromises = overlayAssets
      .filter(asset => visibleLayerIds.includes(asset.layerId))
      .map(asset => new Promise(
        (res, rej) => createKonvaShape(asset, refVideo, { scale, boundingRect })
          .then(shape => {
            const videoPosition = getVideoPosition({
              offset: asset.settings.offset,
              size: {
                width: shape.width(),
                height: shape.height(),
              },
              scale,
              boundingRect,
              rotation: asset.settings.rotation,
              outlineWidth: asset.settings.outlineWidth,
            })
            preparedImages.push({ assetId: asset.id, videoPosition })
            shape.toCanvas().toBlob(img => {
              if (img.size > 10_000_000) {
                rej(new Error('Too large image file. Max size is 10mb'))
              } else {
                assetBlobs.push({ assetId: asset.id, blob: img })
                res(img)
              }
            })
          })
      ))

    await Promise.all(imageCreationPromises)

    preparedImages = (await dispatch(uploadOverlays(assetBlobs)))
      .map(({ assetId, data }) => ({
        ...preparedImages.find(({ assetId: aId }) => aId === assetId),
        ...data,
      }))

    let taskId = null
    let fileId = null
    if (__CFG__.RENDER_BACKEND === RENDER_BACKEND.SOLVEIG) {
      const assets = Selectors.getFlatTimeline(getState())
      const layersIndexes = Selectors.getLayersIndexes(getState())
      const editingXTL = xmlFormat(
        await createEditingXTL(
          outputName,
          format,
          assets,
          layersIndexes,
          preparedImages,
          previewParams,
          refVideo,
          visibleLayerIds
        )
      )
      console.log(editingXTL)
      const response = await createEditingTask(editingXTL)
      const { error } = response.data
      fileId = response.data.fileId
      taskId = response.data.taskId
      if (error) {
        throw Error(error)
      }
    } else if (__CFG__.RENDER_BACKEND === RENDER_BACKEND.SHOTSTACK) {
      const project = selectProjectForSaving(state)
      const timeline = createShotStackTimeline(project, refVideo, preparedImages)
      const response = await createEditingTaskShotStack(timeline)
      taskId = response.data.response.id
    }
    const task = {
      id: taskId,
      projectId: activeProjectId,
      status: TASK_STATUS.NEW,
      fileId,
      progress: 0,
      error: '',
      outputUrls: [],
    }
    if (__CFG__.SAVE_SETTINGS.CREATE_OUTPUT_MEDIA) {
      dispatch(createMediaFile(fileId, FILE_TYPE.OUTPUT))
    }
    dispatch(addEditingTask({ task }))
    setTimeout(checkStatusLongPolling, 0, dispatch, taskId)
  } catch (error) {
    const msg = error.data?.error || error.data?.message || error.statusText || error.toString()
    const err = new Error(msg)
    dispatch(addEditingTaskError({ err }))
  }
}
