import produce from 'immer'
import { isError } from 'flux-standard-action'
import * as ActionTypes from 'actions/ActionTypes'
import { DEFAULT_PROJECT_OUTPUT_NAME } from '~/constant'
import { DEFAULT_VIDEO_HEIGHT, DEFAULT_VIDEO_WIDTH, DEFAULT_FPS } from '~/Util'
import { TASK_STATUS } from '~/enums'

const getInitialReference = () => ({
  id: null,
  width: DEFAULT_VIDEO_WIDTH,
  height: DEFAULT_VIDEO_HEIGHT,
  fpsNum: DEFAULT_FPS,
  fpsDenum: 1,
})

const initialState = {
  editingTasks: [],
  outputName: DEFAULT_PROJECT_OUTPUT_NAME,
  outputPath: '',
  loading: false,
  error: null,
  selectedTaskId: null,
  reference: getInitialReference(),
}
const setSelectedTask = (state, action) => {
  const { id } = action.payload
  state.selectedTaskId = id
}

const prepareTask = (state, action) => {
  const { name, path } = action.payload
  state.error = null
  state.loading = true
  state.outputName = name
  state.outputPath = path || ''
}

const addTask = (state, action) => {
  if (isError(action)) {
    const { err } = action.payload
    state.error = err
    console.error(err)
    return
  }
  state.error = null
  if (action.meta.loading) {
    state.loading = true
  } else if (!action.meta.loading) {
    state.loading = false
    const { task } = action.payload
    state.editingTasks.push(task)
    state.selectedTaskId = task.id
  }
}

const deleteTask = (state, action) => {
  if (isError(action)) {
    const { err } = action.payload
    state.error = err
    console.error(err)
    return
  }
  const { taskId } = action.payload
  state.editingTasks = state.editingTasks.filter(task => task.id !== taskId)
  if (taskId === state.selectedTaskId) {
    state.selectedTaskId = null
  }
}

const updateTaskStatus = (state, action) => {
  const { task } = action.payload
  if (action.error) {
    const { id, message } = action.payload
    const failedTask = state.editingTasks.find(t => t.id === id)
    if (failedTask) {
      failedTask.error = message
      failedTask.status = TASK_STATUS.ABORTED
    }
  } else {
    const index = state.editingTasks.findIndex(x => x.id === task.id)
    if (index !== -1) {
      Object.assign(state.editingTasks[index], task)
    }
  }
}

const project = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.ADD_EDITING_TASK:
      addTask(state, action)
      break
    case ActionTypes.PREPARE_EDITING_TASK:
      prepareTask(state, action)
      break
    case ActionTypes.DELETE_EDITING_TASK:
      deleteTask(state, action)
      break
    case ActionTypes.UPDATE_EDITING_TASK_STATUS:
      updateTaskStatus(state, action)
      break
    case ActionTypes.SET_SELECTED_TASK:
      setSelectedTask(state, action)
      break
    case ActionTypes.SET_REFERENCE: {
      state.reference = action.payload || getInitialReference()
      break
    }
    case ActionTypes.RESET_MAIN_VIEW: {
      state.reference = getInitialReference()
      break
    }
  }
}

export default produce(project, initialState)
