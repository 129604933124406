import { MuiThemeProvider, StylesProvider, createMuiTheme } from '@material-ui/core'
import MobilePlug from 'components/MobilePlug'
import { TranslationProvider } from 'providers/TranslationProvider'
import { NWShortCutProvider } from 'providers/NWShortCutProvider'
import React, { useCallback, useState } from 'react'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import 'styles/split.css'
import 'styles/style.scss'
import AppVersion from '~/AppVersion'

import { RoutingAnalytics } from '~/services/AnalyticsService/RoutingAnalytics'
import './App.css'
import MainViewContainer from './MainViewContainer'
import { DynamicScripts } from './dynamicScripts/DynamicScripts'
import { useScriptsList } from './dynamicScripts/useScriptsList'
import store from './store'

const getBasename = path => {
  const slashedPath = `${path}/`
  return slashedPath.substr(0, slashedPath.lastIndexOf('/'))
}

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: 'var(--popup-background)',
        border: '1px solid var(--popup-border)',
      },
      rounded: {
        borderRadius: '4px',
      },
      elevation8: {
        boxShadow: 'none',
      },
    },
    MuiPopover: {
      paper: {
        overflow: 'hidden',
        marginTop: '3px',
        overflowY: 'hidden',
      },
    },
    MuiExpansionPanel: {
      root: {
        border: 'none',
      },
    },
    MuiSlider: {
      thumb: {
        '&.Mui-disabled': {
          width: '12px',
          height: '12px',
          color: '#5A5A5A',
          marginLeft: '-6px',
        },
      },
    },
    MuiButton: {
      root: {
        '&.Mui-disabled': {
          color: '#FFF',
          opacity: 0.5,
        },
      },
    },
    MuiInput: {
      root: {
        color: '#FFF',
      },
    },
  },
})

function App() {
  const isTouchDevice = 'ontouchstart' in document

  const [ versionFetched, setVersionFetched ] = useState(false)

  // NOTE: load all dynamic scripts-list
  const [ scriptsList ] = useScriptsList()

  const onSuccessGetVersion = useCallback(() => {
    setVersionFetched(true)
  }, [])

  return (
    <Provider store={store}>
      <Router basename={getBasename(window.location.pathname).includes('/edit') ? 'edit' : '/'}>
        <Choose>
          <When condition={!versionFetched}>
            <AppVersion onSuccess={onSuccessGetVersion} />
          </When>
          <Otherwise>
            {!!scriptsList.length && (
              <DynamicScripts sources={scriptsList} />
            )}
            <RoutingAnalytics>
              <NWShortCutProvider>
                <DndProvider backend={Backend}>
                  <TranslationProvider>
                    <StylesProvider injectFirst>
                      <MuiThemeProvider theme={theme}>
                        {isTouchDevice ? <MobilePlug /> : <MainViewContainer />}
                      </MuiThemeProvider>
                    </StylesProvider>
                  </TranslationProvider>
                </DndProvider>
              </NWShortCutProvider>
            </RoutingAnalytics>
          </Otherwise>
        </Choose>
      </Router>
    </Provider>
  )
}

export default App
