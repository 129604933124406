/* eslint-disable no-alert */
import { showCustomizeView } from 'actions/mainView'
import {
  addHotkeyProfile,
  deleteHotkeyProfile,
  renameHotkeyProfile,
  resetProfileToDefaults,
  searchHotkey as searchHotkeyAction,
  setActiveHotkeyProfile,
  setHotkey as setHotkeyAction
} from 'actions/user'
import SearchMenu from 'components/AssetPanels/Audio/SearchMenu'
import { Chip } from 'components/Chip/Chip'
import { CrumbButton } from 'components/CrumbButton/CrumbButton'
import { DeleteHotkeyProfilePresetModal } from 'components/DeleteHotkeyProfilePresetModal/DeleteHotkeyProfilePresetModal'
import { HotkeysTable } from 'components/HotkeysTable/HotkeysTable'
import { InputModal } from 'components/InputModal/InputModal'
import { View } from 'components/View/View'
import ButtonBase from 'components/base/Button'
import { DropdownMenu } from 'components/base/DropdownMenu/DropdownMenu'
import MessageBox from 'components/base/MessageBox'
import { SearchInput } from 'components/base/SearchInput/SearchInput'
import { CATEGORIZED_HOTKEYS } from 'constant'
import { TranslationContext } from 'contexts/TranslationContext'
import { HOTKEY_CATEGORY } from 'enums'
import { useAction, useModalDialog } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  activeHotkeyProfileSelector,
  hotkeyProfilesOptionsSelector
} from 'selectors/user-profile-selector'
import classNames from 'classnames'
import { ReactComponent as UndoIcon } from '~/assets/icons/undo.svg'
import { ReactComponent as WarningIcon } from '~/assets/icons/warning.svg'
import styles from './CustomizationHotkeys.module.scss'

const menuActions = [ 'ADD_NEW', 'RENAME', 'DELETE' ]

export const CustomizationHotkeys = () => {
  const { t } = React.useContext(TranslationContext)
  const [ categoryId, setCategoryId ] = useState(HOTKEY_CATEGORY.All)
  const [ categories ] = useState(() => Object.values(HOTKEY_CATEGORY)
    .map(c => ({ id: c, name: t(c) })))
  const [ conflictCombination, setConflictCombination ] = useState('')
  const [ conflictHotkey, setConflictHotkey ] = useState('')
  const onClose = useAction(showCustomizeView, false)
  const setActive = useAction(setActiveHotkeyProfile)
  const rename = useAction(renameHotkeyProfile)
  const deleteProfile = useAction(deleteHotkeyProfile)
  const resetToDefaults = useAction(resetProfileToDefaults)
  const [ rerenderSeed, setRerenderSeed ] = useState(1)
  const setHotkey = useAction(setHotkeyAction)
  const searchHotkey = useAction(searchHotkeyAction)

  const [ undoStack, setUndoStack ] = useState([])

  const renameModal = useModalDialog({
    onConfirm: newName => {
      rename(newName)
    },
  })

  const [ error, setError ] = React.useState('')

  const handleChangeProfile = profile => {
    setActive(profile)
  }

  const hotkeyProfilesOptions = useSelector(hotkeyProfilesOptionsSelector)
  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)

  const handleConflict = (conflictHotkey, combination) => {
    setConflictCombination(combination)
    setConflictHotkey(conflictHotkey)
    setRerenderSeed(Math.random())
  }

  const handleChanged = (hotkey, combination, oldCombination) => {
    setUndoStack(v => [ ...v, { hotkey, oldCombination }])
    setConflictCombination('')
    setConflictHotkey('')
    setRerenderSeed(Math.random())
  }

  const handleClickUndo = () => {
    if (undoStack.length) {
      setUndoStack(v => {
        const { hotkey, oldCombination } = v.pop()
        setHotkey(hotkey, oldCombination)

        if (conflictCombination) {
          setHotkey(conflictHotkey, conflictCombination)
          setConflictCombination('')
          setConflictHotkey('')
        }
        return v
      })
    }
    setRerenderSeed(Math.random())
  }

  useEffect(() => {
    setConflictCombination('')
    setConflictHotkey('')
    setRerenderSeed(Math.random())
    setUndoStack([])
  }, [ activeHotkeyProfile ])

  // const deleteProfile = useAction(deleteHotkeyProfile)
  const addProfile = useAction(addHotkeyProfile)

  const deletionDialog = useModalDialog({
    onConfirm: async () => {
      deleteProfile(activeHotkeyProfile.id)
    },
  })

  const actions = menuActions
    .map(a => ({ id: a, text: t(a), disabled: activeHotkeyProfile.readonly && (a === 'DELETE' || a === 'RENAME') }))

  const handleClickAction = actionId => {
    switch (actionId) {
      case 'ADD_NEW': {
        addProfile()
        break
      }
      case 'RENAME': {
        renameModal.open()
        break
      }
      case 'DELETE': {
        deletionDialog.open()
        break
      }
      default: {
        break
      }
    }
  }

  const handleResetAll = () => {
    setUndoStack([])
    resetToDefaults()
    setRerenderSeed(Math.random())
  }

  const handleSearch = v => {
    searchHotkey(v)
  }

  return (
    <>
      <View
        footer={
          // eslint-disable-next-line no-nested-ternary
          conflictHotkey ? (
            <div
              className="flex j-space-between items-center"
              style={{ width: '100%' }}
            >
              <div className={classNames(styles.conflictMessage, 'flex')}>
                <WarningIcon style={{ marginRight: '12px' }} />
                {`${conflictCombination} was previously assigned to ${t(
                  CATEGORIZED_HOTKEYS[conflictHotkey]
                )} > ${t(conflictHotkey)}`}
                <br />
                Video Editor unassigned the shortcut for that command
              </div>
              <ButtonBase
                style={{ width: 'fit-content' }}
                border
                className={styles.undoButton}
                onClick={handleClickUndo}
              >
                <UndoIcon className={styles.undoIcon} />
                Undo
              </ButtonBase>
            </div>
          ) : undoStack.length ? (
            <div
              className="flex j-space-between items-center"
              style={{ width: '100%' }}
            >
              <ButtonBase
                color="primary"
                text
                size="small"
                style={{ textTransform: 'unset', fontSize: '14px' }}
                onClick={handleResetAll}
              >
                {t('RESET_ALL_TO_DEFAULTS')}
              </ButtonBase>
              <ButtonBase
                style={{ width: 'fit-content' }}
                border
                className={styles.undoButton}
                onClick={handleClickUndo}
              >
                <UndoIcon className={styles.undoIcon} />
                Undo
              </ButtonBase>
            </div>
          ) : null
        }
        onClose={onClose}
        title={t('SETTINGS_HOTKEYS')}
      >
        <div className={styles.presetConfigLine}>
          <div className="flex items-center">
            <span>
              {t('PRESET')}
              :
            </span>
            <SearchMenu
              items={hotkeyProfilesOptions}
              useReset={false}
              onSelect={handleChangeProfile}
              category={activeHotkeyProfile.id}
              search=""
              className={styles.presetDropdown}
            />
            <DropdownMenu
              emitFromContent
              hideChevron
              menuItems={actions}
              onClick={handleClickAction}
              dropdownClassName={styles.hotkeyDropdown}
            >
              <CrumbButton />
            </DropdownMenu>
          </div>
          <SearchInput
            className={styles.hotkeysSearch}
            placeholder={t('SEARCH_COMMAND')}
            onSearch={handleSearch}
          />
        </div>
        <div className={styles.presetChips}>
          {categories.map(category => (
            <Chip
              key={category.id}
              className={styles.hotkeyChip}
              onClick={() => setCategoryId(category.id)}
              label={category.name}
              color={categoryId === category.id ? 'primary' : undefined}
            />
          ))}
        </div>
        <HotkeysTable
          onChanged={handleChanged}
          categoryId={categoryId}
          onConflict={handleConflict}
          key={rerenderSeed}
        />
      </View>
      {error && (
        <MessageBox
          title="Customization saving error"
          message={error}
          onClose={() => setError(false)}
        />
      )}
      <InputModal
        fieldName={t('ENTER_PRESET_NAME')}
        title={t('KEYBOARD_MAPPING_PRESET')}
        value={activeHotkeyProfile.id}
        modalDialog={renameModal}
      />
      <DeleteHotkeyProfilePresetModal
        modalDialog={deletionDialog}
        name={activeHotkeyProfile.id}
      />
    </>
  )
}
