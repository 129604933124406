import { MediaRecordingAsset, PlaceholderAsset, TransitionAsset } from 'models/Asset'


// Take from each layer one asset, placed at current slider position (except of placeholder assets).
export const getPlayingAssets = ({ sliderTime, assetsByLayers }) => {
  const layers = Array.from(assetsByLayers.values())
  // Transition is an asset, but it can't be previwed independently,
  // so filter it out from the 'playing' assets
    .map(layer => layer.filter(asset => !(asset instanceof TransitionAsset
      || asset instanceof MediaRecordingAsset)))
  let assets = layers.map(layer => layer.find(asset => asset.matchesPlayingTime(sliderTime)))

  assets = assets.filter(x => x !== undefined)

  assets = PlaceholderAsset.reject(assets)
  return assets
}
