import { generateClientId } from 'models/Asset/Asset'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUniqueTimelineFileExtenstions } from 'selectors'
import { insertLayer } from 'actions/insertLayer'
import * as Actions from '~/actions'
import { DRAGNDROP_TYPE } from '~/enums'
// import { useLatestRef } from '~/hooks'
import { pixel2Time } from '~/Util'
import { getConflictedFileExtensions } from '~/incompatibleFileExtensionsMap'
import { DraggingItemContext } from '../DraggingItemProvider'


export const useLayerDropHandler = () => {
  const { getProps: getDraggingItemProps,
    resetProps: resetDraggingItemProps } = React.useContext(DraggingItemContext)

  const dispatch = useDispatch()

  const uniqueTimelineFileExtensions = useSelector(selectUniqueTimelineFileExtenstions)

  const handleDropOnLayer = useCallback((item, monitor, {
    onDenied, scale, layerIndex, layerId,
  }) => {
    const { fileExtension, incompatibleFileExtensions } = item
    if (fileExtension && incompatibleFileExtensions) {
      const conflictedFileExtensions = getConflictedFileExtensions(
        incompatibleFileExtensions, uniqueTimelineFileExtensions
      )
      if (incompatibleFileExtensions.length && conflictedFileExtensions.length) {
        resetDraggingItemProps()
        onDenied(`Please remove existing ${conflictedFileExtensions.map(ext => ext.toUpperCase()).join(', ')}
        files to add ${fileExtension.toUpperCase()}. The files are not compatible.`)
        return
      }
    }

    if (monitor.didDrop()) {
      resetDraggingItemProps()
      return
    }

    const isMultipleDrop = item.selectedAssets?.length > 1
    && item.selectedAssets.find(asset => asset.id === item.id)

    let action
    let pregeneratedId
    switch (item.type) {
      case DRAGNDROP_TYPE.LAYER_ASSET:
        isMultipleDrop
          ? action = Actions.timeline.moveMultipleAssets
          : action = Actions.timeline.moveAsset
        break

      default:
        if (item.type !== DRAGNDROP_TYPE.LAYER
           && item.type !== DRAGNDROP_TYPE.TRANSITION_ITEM
           && item.type !== DRAGNDROP_TYPE.LAYER_TRANSITION_ASSET) {
          action = Actions.timeline.dropSourceFile
          pregeneratedId = generateClientId()
        }
        break
    }
    const {
      normalizedSourceX,
      deltaOnDrop,
      isOverItem,
      allowDropOnHover,
      overNewLayerIndex,
      disableDrop,
      dropOffsetX,
      draggableBlockStartTime,
      isIntersectionPlaceholder,
      intersectionPlaceholder,
    } = getDraggingItemProps()

    resetDraggingItemProps()
    dispatch(Actions.timeline.endDragAsset())

    if (disableDrop || !action) {
      return
    }

    if (overNewLayerIndex !== null) {
      !isMultipleDrop && dispatch(insertLayer(overNewLayerIndex))
      dispatch(action(item.id, {
        startTime: Math.max(0, pixel2Time(normalizedSourceX, scale)),
        layerIndex: overNewLayerIndex,
        pregeneratedId,
        isTransit: true,
        dragItem: item,
        isNewLayerAction: true,
        dropOffsetX: pixel2Time(dropOffsetX, scale),
        isIntersectionPlaceholder,
        intersectionPlaceholder,
      }))
    } else if ((isOverItem.size === 1 && allowDropOnHover) || isOverItem.size === 0) {
      dispatch(Actions.layer.shiftItemsByAssetOnDrop({ id: pregeneratedId || item.id,
        layerId,
        ignoredAssetsIds: isMultipleDrop ? item.selectedAssets.map(asset => asset.id) : [],
        time: Math.max(0, pixel2Time(normalizedSourceX, scale)),
        delta: pixel2Time(deltaOnDrop, scale),
        isIntersectionPlaceholder,
        intersectionPlaceholder }))

      dispatch(action(item.id, {
        startTime: Math.max(0, pixel2Time(normalizedSourceX, scale)),
        layerIndex,
        pregeneratedId,
        deltaOnDrop: pixel2Time(deltaOnDrop, scale),
        dragItem: item,
        isNewLayerAction: false,
        isOverItem,
        dropOffsetX: pixel2Time(dropOffsetX, scale),
        draggableBlockStartTime,
        isIntersectionPlaceholder,
        intersectionPlaceholder,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ uniqueTimelineFileExtensions ])
  return handleDropOnLayer
}
