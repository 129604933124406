import { useCallback, useRef } from 'react'
import { ContextStateType } from 'contexts/ProjectContext'

type UseProjectContextMenu = {
    handleContextMenu(e: MouseEvent | KeyboardEvent | null, data?: ContextStateType): void
}

export const useProjectContextMenu = ({ handleContextMenu }: UseProjectContextMenu) => {
  const refProject = useRef<HTMLDivElement | null>(null)

  const openContextMenu = useCallback((e: MouseEvent, projectId: string) => {
    e.preventDefault()
    e.stopPropagation()
    if (refProject.current) {
      const { top, left } = refProject.current.getBoundingClientRect()
      const contextMenuTop = e.clientY - top
      const contextMenuLeft = e.clientX - left
      handleContextMenu(e, {
        projectId,
        visible: true,
        top: contextMenuTop < 0 ? 0 : contextMenuTop,
        left: contextMenuLeft < 0 ? 0 : contextMenuLeft,
      })
    }
  }, [ handleContextMenu ])

  return { openContextMenu, refProject }
}
