import React, { useContext } from 'react'
import { isArray, isEmpty } from 'lodash'
import { LAYER_ASSET_HEIGHT, LAYER_BORDER } from '~/constant'
import { DRAGNDROP_TYPE } from '~/enums'
import LayerItem from '~/components/Timeline/LayerItem'
import { getDragAssetsByLayerIndexId } from './getDragAssetsByLayerIndexId'
import { getDragAssetsLeftOffset } from './getDragAssetsLeftOffset'
import { TranslationContext } from '~/contexts/TranslationContext'


const LayerItemDraggingContainer = ({ draggingAsset, styles }) => {
  const { t } = useContext(TranslationContext)
  return (
    <LayerItem
      style={styles}
      asset={draggingAsset}
      key={draggingAsset.id}
      asDragPreview
      onTranslate={t}
    />
  )
}

const getLayerItemCollection = (dragAssetsByLayerId, leftOffsets, externalStyles) => (
  <div>
    {Object.entries(dragAssetsByLayerId).map(([ layerId, assets ]) => (
      <div key={layerId} style={{ display: 'flex', marginBottom: `${LAYER_BORDER * 2}px` }}>
        {!isEmpty(assets) ? assets
          .map(asset => {
            const layerItem = (
              <LayerItemDraggingContainer
                draggingAsset={asset}
                styles={{ ...externalStyles, left: leftOffsets[asset.id] }}
              />
            )
            return <div key={asset.id} style={{ position: 'relative', height: LAYER_ASSET_HEIGHT }}>{layerItem}</div>
          }) : <div style={{ position: 'relative', height: LAYER_ASSET_HEIGHT, width: '1px' }} />}
      </div>
    ))}
  </div>
)

export const getDraggingElement = (dragItem, draggingAssets, scale, transitions) => {
  switch (dragItem.type) {
    case DRAGNDROP_TYPE.MEDIA_ITEM:
    case DRAGNDROP_TYPE.TEXT_ITEM:
    case DRAGNDROP_TYPE.AUDIO_ITEM:
    case DRAGNDROP_TYPE.IMAGE_ITEM:
    case DRAGNDROP_TYPE.LAYER_ASSET:
    // case DRAGNDROP_TYPE.LAYER_TRANSITION_ASSET:
      return isArray(draggingAssets)
        ? getLayerItemCollection(getDragAssetsByLayerIndexId(draggingAssets, dragItem),
          getDragAssetsLeftOffset(draggingAssets, scale, transitions), { borderColor: '#576fe9', opacity: 0.5, height: LAYER_ASSET_HEIGHT })
        : (
          <LayerItemDraggingContainer
            draggingAsset={draggingAssets}
            styles={{ left: 0, top: 0, borderColor: '#576fe9', opacity: 0.5, height: LAYER_ASSET_HEIGHT }}
          />
        )
    default: return null
  }
}
