/* Values from this file will be base for any other preset */
export default {
  LOGO_PATH: null,
  FAVICON: '',
  TITLE: 'Online Video Editor',
  DESCRIPTION: 'Free Online Video Editor of SolveigMM',
  ACTIVATION_BAR_APP: 'Online Video Editor',
  COMPANY_NAME: 'Solveig Multimedia',

  LOGO_LINK: null,

  STUDIO_HOST: null,
  SMARTEDITING_HOST: null,

  HEADER: {
    TITLE_VISIBLE: true,
  },

  LAYER: {
    MUTE: true,
    HIDE: true,
    REARRANGE: true,
  },

  PREVIEW: {
    KEY_FRAME_STEPPER: true,
  },

  TIMELINE: {
    UNDO_REDO: true,
    RECORD_AUDIO: true,
    ADD_MARKER: true,
    TIME_INPUT: true,
    ZOOM_MENU: true,
    CUT: true,
    DELETE: true,
    SETTINGS: true,
    TOOLBAR: true,
  },

  SOURCE_FILES_MANAGEMENT: {
    SEARCH: true,
    FILTER: true,
    VIEW_OPTIONS: true,
    VIEW_MODE_SETTINGS: true,
    LOAD_MEDIA_ONSCROLL: true,
    LOADING_NUMBER: 12,
    SUPPORTED_FILES: [ '.mp4', '.mov', '.mxf', 'ts', '.mp3', '.m4a', '.png', '.jpg', '.jpeg' ],
    RETRY_UPLOAD: true,
    IMPORT_DROPDOWN: true,
    IMPORT_FOLDER: true,
    IMPORT_DROPBOX: true,
    IMPORT_AMAZON: true,
    // TODO: change dropbox app key
    DROPBOX_APP_KEY: '9qs0x8f8crarg47',
    IMPORT_GDRIVE: true,
    GOOGLE_API_KEY: 'AIzaSyA4WPuAKIdjAe_Z8oCRiYZcn4sdc5MkN80',
    GOOGLE_CLIENT_ID: '804787470420-jpns7ku2kirge5cgvgr4qp0nq65vpd8r.apps.googleusercontent.com',
    IMPORT_URL: true,
    SELECTABLE: true,
    MOVE_TO_FOLDERS: true,
    TURN_SERVER: { urls: `turn:${__BACKEND_HOST__ || window.location.hostname}:3478`, username: 'user', credential: '12345678' },
  },

  SOURCE_FILES_TYPES: {
    MEDIA: true,
    AUDIO: true,
    TEXT: true,
    TRANSITIONS: true,
    FILTERS: true,
    DESIGN: true,
    IMAGES: true,
    VIDEOS: true,
  },

  SOURCE_FILES_SORTS: {
    NAME: true,
    DATE: true,
    SIZE: true,
    DURATION: true,
  },

  SOURCE_FILES_FILTERS: {
    ALL: true,
    VIDEO: true,
    IMAGES: true,
    AUDIO: true,
    FOLDERS: true,
    MEDIA_RECORDING: {
      VOICEOVER: true,
      WEBCAM: true,
    },
  },

  MEDIA_ITEM: {
    CODECS_INFO: true,
  },

  MANAGE_PROJECTS: true,
  IMPORT_MEDIA: true,

  BTN_DELETE_CLIP: true,
  BTN_ADD_CLIP: true,

  MAX_VIDEO_ASSETS_COUNT: -1, // -1 for infinite

  SAVE_PROJECT_MENU: {
    DETAILED_PROJECT_STATS_ON_SAVE: true,
    CHOOSE_OUTPUT_FILE_FORMAT: true,
    SAVE_PROJECT_BUTTON: null,
    FILENAME_TITLE: false,
    MAX_FILENAME_LENGTH: 250, // file name only without ext
  },

  SAVE_SETTINGS: {
    CLOSE_CONFIRMATION_ENABLED: false,
    CREATE_OUTPUT_MEDIA: true,
    CLOSE_ON_CANCEL: false,
    DELETE_OUTPUT_ON_CLOSE: false,
    CONFIRMATION_TITLE: 'Close Project?',
    CLEAR_TIMELINE_ON_CLOSE: false,
    REDIRECT_TO_PROJECTS_ON_CLOSE: false,
  },

  MEDIA_SETTINGS: {
    VOLUME: true,
    IMAGE_SHADOW: false,
  },

  AUDIO_SETTINGS: {
    VOLUME: true,
    TRANSITIONS: true,
  },

  GENERATE_AUTH_TOKEN: true,
  CUSTOM: {},

  DISALLOW_INCOMPATIBLE_FILES: false,

  AUDIO_CATEGORIES: [ 'music', 'mood', 'sound' ],
  IMAGES_AI_SOURCE: true,

  MAX_TEXT_BLUR: 350,
  MIN_TEXT_BLUR: 0,
  MAX_FONT_SIZE: 320,
  MIN_FONT_SIZE: 10,

  PROJECT_IMPORT: true,

  RENDER_BACKEND: 'solveig',

  ACTIVATION_TARIFFS_URL: null,

  IS_CHROMAKEY_ENABLED: true,
}
