/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import Fade from '@material-ui/core/Fade'
import Tooltip from '@material-ui/core/Tooltip'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import { recording as recordingSelectors } from 'selectors'
// import { ReactComponent as DoubleRewindIcon } from '~/assets/video-player/ic_double_rewind.svg'
import { addSourceFileAtSliderPosition } from 'actions/addSourceFileAtSliderPosition'
import { TranslationContext } from 'contexts/TranslationContext'
import { HOTKEYS } from 'enums'
import { useHotkeyTooltip } from 'hooks/useHotkeyTooltip'
import { useHotkeys } from 'react-hotkeys-hook'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import { DEFAULT_FPS, refTimeToHHMMSSMSMS, secondsToTimelineTime, timelineTimeToSeconds } from '~/Util'
import { ReactComponent as AssetAddIcon } from '~/assets/video-player/ic_asset_add.svg'
import { ReactComponent as FullscreenIcon } from '~/assets/video-player/ic_fullscreen.svg'
import { ReactComponent as PlayIcon } from '~/assets/video-player/ic_play.svg'
import { ReactComponent as FrameStepIcon } from '~/assets/video-player/ic_rewind.svg'
import { ReactComponent as StopIcon } from '~/assets/video-player/ic_stop.svg'
import { ReactComponent as UnmutedIcon } from '~/assets/video-player/ic_volume.svg'
import { ReactComponent as MutedIcon } from '~/assets/video-player/ic_volume_off.svg'
import RawButton from '~/components/base/RawButton'
import Slider from '~/components/base/Slider'
import { useAction, useBind } from '~/hooks'
import './PreviewToolbar.scss'
import { getProjectParams, useFrameStepHandlers, useTimelineRewindHandler } from './TimelinePreview/lib'

function PreviewToolbar(props) {
  const {
    playing, volume, muted, fps,
    onPlayPause, onFullscreen, onChangeVolume, onToggleMute,
    onRewindTimeline, onPrevFrame, onNextFrame,
    duration, progress,
  } = props

  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)

  const project = useSelector(getProjectParams)

  const selectedClipId = useSelector(state => state.playback.selectedClipId)
  const onAddToTimeline = useAction(addSourceFileAtSliderPosition, selectedClipId)

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.ADD_TO_TIMELINE], onAddToTimeline)

  const mediaRecording = useSelector(recordingSelectors.isRecordingStarted)
  const isSaving = useSelector(recordingSelectors.isRecordingSaving)
  const recordingCountdownStarted = useSelector(recordingSelectors.selectShowCountdown)
  const disabledCtrls = mediaRecording || isSaving
  // TODO: Refactor this selector for separate of sliderTime and move this into the TimelinePreview component
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const _duration = timelineTimeToSeconds(project.duration)
  const _progress = timelineTimeToSeconds(project.progress)

  const _onRewindTimeline = useTimelineRewindHandler()
  const { onPrevFrame: _onPrevFrame, onNextFrame: _onNextFrame } = useFrameStepHandlers(fps)
  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.PREV_FRAME], onPrevFrame || _onPrevFrame)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.NEXT_FRAME], onNextFrame || _onNextFrame)

  const toggleMute = useBind(onToggleMute, volume)

  const generalProgress = selectedClipId || progress ? progress : _progress

  const { t } = useContext(TranslationContext)
  const { getTooltip } = useHotkeyTooltip()

  return (
    <div className="preview-player-toolbar">
      <div className={cx('preview-player-toolbar__timeline', { 'preview-player-toolbar--disabled': disabledCtrls })}>
        <Slider
          className="preview-player-toolbar__slider"
          value={generalProgress}
          onChange={onRewindTimeline || _onRewindTimeline}
          max={duration || _duration}
          step={0.01}
        />
      </div>

      <div className="preview-player-toolbar__buttons">
        <div className="preview-player-toolbar__left">
          {refTimeToHHMMSSMSMS(secondsToTimelineTime(generalProgress))}
        </div>

        <div className="preview-player-toolbar__center">
          {/* <If condition={__CFG__.PREVIEW.KEY_FRAME_STEPPER}>
            <div className="preview-player-toolbar__item">
              <RawButton disabled={disabledCtrls}>
                <DoubleRewindIcon />
              </RawButton>
            </div>
          </If> */}

          <div className="preview-player-toolbar__item" title={getTooltip(t('PREVIEW_BTN_PREV_TOOLTIP'), HOTKEYS.PREV_FRAME)}>
            <RawButton onClick={onPrevFrame || _onPrevFrame} disabled={disabledCtrls} dataLangId="PREVIEW_BTN_PREV">
              <FrameStepIcon />
            </RawButton>
          </div>

          <div className="preview-player-toolbar__item" title={getTooltip(!playing ? t('PREVIEW_BTN_PLAY_TOOLTIP') : t('PREVIEW_BTN_STOP_TOOLTIP'), HOTKEYS.PLAY_PAUSE)}>
            <RawButton onClick={onPlayPause} disabled={recordingCountdownStarted || isSaving} dataLangId={!playing ? 'PREVIEW_BTN_PLAY' : 'PREVIEW_BTN_STOP'}>
              {playing ? <StopIcon /> : <PlayIcon />}
            </RawButton>
          </div>

          <div className="preview-player-toolbar__item" title={getTooltip(t('PREVIEW_BTN_NEXT_TOOLTIP'), HOTKEYS.NEXT_FRAME)}>
            <RawButton onClick={onNextFrame || _onNextFrame} disabled={disabledCtrls} dataLangId="PREVIEW_BTN_NEXT">
              <FrameStepIcon className="revert-icon" />
            </RawButton>
          </div>

          {/* <If condition={__CFG__.PREVIEW.KEY_FRAME_STEPPER}>
            <div className="preview-player-toolbar__item">
              <RawButton disabled={disabledCtrls}>
                <DoubleRewindIcon className="revert-icon" />
              </RawButton>
            </div>
          </If> */}
        </div>


        <div className="preview-player-toolbar__right">
          <If condition={selectedClipId}>
            <div className="preview-player-toolbar__item">
              <RawButton className="add-icon" onClick={onAddToTimeline} disabled={disabledCtrls}>
                <AssetAddIcon />
              </RawButton>
            </div>
          </If>
          <div className="preview-player-toolbar__item" title={t('PREVIEW_BTN_VOLUME_TOOLTIP')}>
            <Tooltip
              classes={{
                tooltip: 'preview-player-toolbar__volume-tooltip',
              }}
              TransitionComponent={Fade}
              placement="top"
              disableHoverListener={disabledCtrls}
              disableFocusListener={disabledCtrls}
              disableTouchListener={disabledCtrls}
              interactive
              title={(
                <div className="preview-player-toolbar__volume">
                  <Slider
                    value={muted ? 0 : volume}
                    onChange={onChangeVolume}
                    orientation="vertical"
                    min={0}
                    max={1}
                    step={0.1}
                  />
                </div>
              )}
            >
              <RawButton onClick={toggleMute} dataLangId="PREVIEW_BTN_VOLUME">
                <span>
                  {muted ? <MutedIcon /> : <UnmutedIcon />}
                </span>
              </RawButton>
            </Tooltip>
          </div>

          <div className="preview-player-toolbar__item" title={t('PREVIEW_BTN_FULL_SCREEN_TOOLTIP')}>
            <RawButton onClick={onFullscreen} disabled={disabledCtrls} dataLangId="PREVIEW_BTN_FULL_SCREEN">
              <FullscreenIcon />
            </RawButton>
          </div>
        </div>
      </div>
    </div>
  )
}

PreviewToolbar.defaultProps = {
  fps: DEFAULT_FPS,
  progress: 0,
  duration: 0,
  onRewindTimeline: null,
  onPrevFrame: null,
  onNextFrame: null,
}

PreviewToolbar.propTypes = {
  playing: PropTypes.bool.isRequired,
  muted: PropTypes.bool.isRequired,
  volume: PropTypes.number.isRequired,
  fps: PropTypes.number,
  progress: PropTypes.number,
  duration: PropTypes.number,

  onRewindTimeline: PropTypes.func,
  onPrevFrame: PropTypes.func,
  onPlayPause: PropTypes.func.isRequired,
  onNextFrame: PropTypes.func,
  onChangeVolume: PropTypes.func.isRequired,
  onFullscreen: PropTypes.func.isRequired,
  onToggleMute: PropTypes.func.isRequired,
}

export default React.memo(PreviewToolbar)
